import React, { useState, useEffect } from 'react'
import { Box, Card, Grid, Toolbar, IconButton, Input, TablePagination } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { useStylesTheme } from '../../config'
import { useNavigate } from 'react-router-dom'
import { patternsRoutes } from '../../routes'
import {  PatternDetailModal } from '../../modals';
import { PatternList } from '../../containers/patterns-list'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { LoadingLogoDialog } from '../../dialogs'
import { useReportsContext } from '../../context'

const PatternsPage = ({roles}) => {
    const navigate = useNavigate();
    const {pautas} = useReportsContext()
    const [ openLoader, setOpenLoader ] = useState(false);
    const [ patterns, setPatterns ] = useState([])
    const [ patternsTemp, setPatternsTemp ] = useState([])
    const [ openPatternModal, setOpenPatternModal ] = useState(false)
    const [ rowsPerPage, setRowsPerPage ] = useState(10)
    const [ page, setPage]  = useState(0);
    const [ totalItems, setTotalItems ] = useState(0)

    useEffect(() => {
        if (pautas) {
            setPatternsTemp(pautas.sort((a, b) => {
                if (a.id < b.id) {
                    return -1
                }
                if (a.id > b.id) {
                    return 1
                }
                return 0
            }))
        }
    }, [pautas])

    useEffect(() => {
        if (patternsTemp) {
            initReadList()
        }
    }, [patternsTemp])

    const buscar = (value) => {
        setPage(0)
        if (value.length > 2) {
            const patternsFiltered = pautas.filter((pattern) => (pattern.typepm.match(value) || pattern.idpm.match(value)))
            setPatternsTemp(patternsFiltered)
        } else {
            setPatternsTemp(pautas)
        }
        initReadList()
    }

    const closePatternModal = () => {
        setOpenPatternModal(false)
    }

    const initReadList = () => {
        let lista = []
        for (let i = (0*rowsPerPage); i < (rowsPerPage+(0*rowsPerPage)); i++) {
            if (patternsTemp[i]) {
                lista.push(patternsTemp[i])
            }
            if (i === ((rowsPerPage+(0*rowsPerPage)) - 1)) {
                const listaCache = [...lista]
                const nuevaLista = listaCache/* .sort((typeReportsSelected === 'Asignar' || typeReportsSelected === 'En proceso') ? compareReverse : compareNumbers) */
                setPatterns(nuevaLista)
                setTotalItems(patternsTemp.length)
            }
        }
        setRowsPerPage(10);
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        let lista = []
        for (let i = (newPage*rowsPerPage); i < (rowsPerPage+(newPage*rowsPerPage)); i++) {
            if (patternsTemp[i]) {
                lista.push(patternsTemp[i])
            }
            if (i === ((rowsPerPage+(newPage*rowsPerPage)) - 1)) {
                const listaCache = [...lista]
                const nuevaLista = listaCache/* .sort(compareNumbers) */
                setPatterns(nuevaLista)
            }
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
        let lista = []
        for (let i = (page*parseInt(event.target.value, 10)); i < (parseInt(event.target.value, 10) + (page*parseInt(event.target.value, 10))); i++) {
            if (pautas[i]) {
                lista.push(pautas[i])
            }
            if (i === ((parseInt(event.target.value, 10) + (page*parseInt(event.target.value, 10))) - 1)) {
                setPatterns(lista)
            }
        }
    }

    return (
        <Box height='100%'>
            <Grid className={'root'} container spacing={0}>
                <Grid className={'pageContainer'} item xs={12}>
                    <Card elevation={0} className={'pageCard'}>
                        <Grid container alignItems='center' justifyContent='center'>
                            <div style={{width: '100%', textAlign: 'left', padding: 10 }}>
                                <div style={{width: '100%', textAlign: 'left', color: '#333', backgroundColor: '#fff', borderRadius: 20 }}>
                                    <Toolbar style={{paddingLeft: 0, backgroundColor: '#F9F9F9', borderRadius: 10}}>
                                        <IconButton onClick={() => setTimeout(() => {
                                            navigate(-1);
                                        }, 500)}> 
                                            <ArrowBackIos style={{color: '#333', fontSize: 16}}/> 
                                        </IconButton> 
                                        <h1 style={{marginTop: 0, marginBottom: 0, fontSize: 16}}>
                                            Administración / Pautas
                                        </h1>
                                        <Input onChange={(e) => {buscar(e.target.value)}} placeholder='Buscar' style={{position: 'absolute', right: 10}}/>
                                    </Toolbar>
                                </div>
                            </div>
                        </Grid>
                        <Grid container alignItems='center' justifyContent='center'>
                            <div style={{width: '100%', textAlign: 'left', padding: 10, overflowY: 'auto', height: 'calc(100vh - 195px)'}}>
                                <PatternList patterns={patterns}/>
                                <TablePagination
                                    component="div"
                                    color={'primary'}
                                    style={{ position: 'absolute', right: 20, bottom: 10, borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid' }}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}                          
                                    count={totalItems}
                                    page={page}
                                />
                            </div>
                        </Grid>
                        <div>
                            <LoadingLogoDialog open={openLoader} />
                            {/* <PatternDetailModal open={openPatternModal} savedInfo={init} closeModal={closePatternModal} /> */}
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PatternsPage