import { useState, useEffect } from "react"
import { Button, Checkbox, Grid, IconButton, List, ListItemButton, Popover } from '@mui/material'
import './style.css'
import { AssignDialog, ReviewReportDialog } from "../../dialogs"
import { useAuth, useReportsContext, useSitesContext, useUsersContext } from "../../context"
import { ColumnasReportes } from "../../pages/reports/ColumnasReportes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp, faEllipsisV, faEye, faUser } from "@fortawesome/free-solid-svg-icons"
import { dateV2 } from "../../config/date_v2"
/* import { CheckBox } from "@mui/icons-material"
 *//* import { pdfMakeRoutes } from "../../routes" */

const ReportsList = ({list, typeReportsSelected, statusReports, tipo, setAllItemsSelected, allItemsSelected, selectItem, selectAllItems}) => {
    const {admin, userData} = useAuth()
    const {sites} = useSitesContext()
    const {elevarReporte} = useReportsContext()
    const {users} = useUsersContext()
    const [ reportData, setReportData ] = useState(null)
    const [ reportDataReview, setReportDataReview ] = useState(null)
    const [ openModalState, setOpenModalState ] = useState(false)
    const [ openReviewModalState, setOpenReviewModalState ] = useState(false)
    const [ siteName, setSiteName ] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [columnas, setColumnas] = useState([])
    const [columnasParaMostrar, setColumnasParaMostrar] = useState([])
    const [listaParaMostrar, setListaParaMostrar] = useState([])
    const [widthTotal, setWidthTotal] = useState(0)
    const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
    const [allChecked, setAllChecked] = useState(false)
    const [indeterminatedChecked, setIndeterminatedChecked] = useState(false)


    const columnasDefault = ColumnasReportes

    const valuesAssignatiosn = [
        'usersAssigned',
        'chiefMachineryApprovedBy',
        'sapExecutiveApprovedBy',
        'shiftManagerApprovedBy'
    ]

    useEffect(() => {
        setColumnas(columnasDefault)
    }, [])

    useEffect(() => {

    }, [])

    useEffect(() => {
        console.log(list)
        setListaParaMostrar(list)
        const contadorDeChecks = list.filter((item) => {return item.checked})
        if (contadorDeChecks.length > 0 && contadorDeChecks.length === list.length) {
            setAllChecked(true)
            setIndeterminatedChecked(false)
        } else if (contadorDeChecks.length === 0) {
            setAllChecked(false)
            setIndeterminatedChecked(false)
        } else if (contadorDeChecks.length > 0 && contadorDeChecks.length < list.length) {
            setAllChecked(false)
            setIndeterminatedChecked(true)
        } else {
            setAllChecked(false)
            setIndeterminatedChecked(false)
        }
    },[list])
    
    const handleClick = (event, siteName) => {
        const siteFiltered = sites.filter((site) => {if (siteName===site.idobra) return site})
        setSiteName(siteFiltered[0].descripcion)
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openModal = (report) => {
        setReportData(report)
        setOpenModalState(true)
    }

    const closeModal = () => {
        setOpenModalState(false)
    }

    const onlyCloseReview = () => {
        setReportDataReview(null)
    }
    
    const onlyClose = () => {
        setOpenModalState(false)
    }

    const openReviewModal = (report) => {
        setReportDataReview(report)
        setOpenReviewModalState(true)
    }    

    const elevar = (report) => {
        if (confirm('Permitirá asignar OT '+report.idIndex+'.')) {
            report.origen = false
            elevarReporte({_id: report._id, origen: false}, report)
        }
    }


    const handleClickPopover = (event) => {
        setAnchorPopoverEl(event.currentTarget);
    };
  
    const handleClosePopover = () => {
        setAnchorPopoverEl(null);
    };

    const usersFiltered = (datas) => {
        if (datas && datas.length > 0) {
            if (Array.isArray(datas)) {
                return datas.map((user) => {
                    return `- ${user.name} ${user.lastName}`
                })
            } else {
                const usersCache = users.find(user => {if (user._id === datas) return user})
                return [usersCache]
            }
        } else {
            return ['- Sin asignaciones']
        }
        
    }

    useEffect(() => {
        const idButton = localStorage.getItem('buttonSelected')
        if (idButton) {
            const columnasGuardadasLocalStorage = localStorage.getItem(`${idButton}_columnasGuardadas`)
            if (columnasGuardadasLocalStorage) {
                setColumnasParaMostrar(JSON.parse(columnasGuardadasLocalStorage).columnasParaMostrar)
            } else {
                setColumnasParaMostrar([])
            }
        }
    }, [localStorage.getItem('buttonSelected')])

    useEffect(() => {            
        if (columnasParaMostrar.length > 0) {
            let total = 0
            columnasParaMostrar.forEach(col => {
                total = total + col.width + 10
            })
            setWidthTotal(total + 350)
            const idButton = localStorage.getItem('buttonSelected')
            if (idButton) {
                const columnasGuardadas = {
                    columnasParaMostrar,
                    idButton
                }
                localStorage.setItem(`${idButton}_columnasGuardadas`, JSON.stringify(columnasGuardadas))
            }
        }
    }, [columnasParaMostrar])
  
    const openPopover = Boolean(anchorPopoverEl);
    const idPopover = openPopover ? 'columns-popover' : undefined;

    const PopoverSelectionColumn = () => {
        const [columnasInternas, setColumnasInternas] = useState([])

        useEffect(() => {
            const idButton = localStorage.getItem('buttonSelected')
            if (idButton) {
                const columnasGuardadasLocalStorage = localStorage.getItem(`${idButton}_columnasGuardadas`)
                if (columnasGuardadasLocalStorage) {
                    const listaSeleccionada = (JSON.parse(columnasGuardadasLocalStorage)).columnasParaMostrar
                    const columnasFiltradas = []
                    columnas.forEach((col) => {
                        listaSeleccionada.forEach((l) => {
                            if (l.value === col.value) {
                                col.checked = l.checked
                            }
                        })
                        columnasFiltradas.push(col)
                    })
                    setColumnasInternas(columnasFiltradas)
                } else {
                    if(columnas.length > 0) {
                        console.log(columnas)
                        const columnasFiltradas = []
                        columnas.forEach((col) => {
                            col.checked = false
                            const res = col.restrict.filter(restrict => {if (typeReportsSelected === restrict) {return restrict}})
                            if (res.length > 0) {
                                columnasFiltradas.push(col)
                            }
                        })
                        /* console.log(columnasFiltradas) */
                        setColumnasInternas(columnasFiltradas)
                    }
                }
            } else {
                setColumnasInternas(columnas)
            }
        }, [columnas])

        const borrarTodo = () => {
            const idButton = localStorage.getItem('buttonSelected')
            if (idButton) {
                localStorage.removeItem(`${idButton}_columnasGuardadas`)
            }
            const columnasCache = [...columnasInternas]
            columnasCache.forEach((col) => {
                col.checked = false
            })
            setColumnasInternas(columnasCache)
            setColumnasParaMostrar([])
            handleClosePopover()
        }
            
        const confirmarSeleccion = () => {
            const columnasSeleccionadasCache = []
            columnasInternas.forEach((columna) => {
                if (columna.checked) {
                    columnasSeleccionadasCache.push(columna)
                }
            })
            setColumnasParaMostrar(columnasSeleccionadasCache.map((col, i) => {
                col.index = i
                return col
            }))
            handleClosePopover()
        }

        const selectColumna = (index) => {
            const columnasCache = [...columnasInternas]
            if (columnasCache[index].checked) {
                columnasCache[index].checked = false
                
            } else {
                columnasCache[index].checked = true
            }
            setColumnasInternas(columnasCache)
        }

        return (
            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorPopoverEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                >
                <div style={{width: 300, height: 520, padding: 10}}>
                    <div style={{ height: 390, overflowY: 'auto' }}>
                        <List>
                            {
                                columnasInternas.map((columna, i) => {
                                    return (
                                        <ListItemButton style={{fontSize: 12}} onClick={() => {selectColumna(i)}}>
                                            <Checkbox checked={columna.checked} /> {columna.name}
                                        </ListItemButton>
                                    )
                                })
                            }
                        </List>
                    </div>
                    <div style={{width: '100%', padding: 10}}>
                        <Button variant={'contained'} style={{marginBottom: 10}} fullWidth onClick={confirmarSeleccion}>
                            Seleccionar
                        </Button>
                        <Button variant={'contained'} fullWidth onClick={borrarTodo}>
                            Borrar Todo
                        </Button>
                    </div>
                </div>
            </Popover>
        )
    }



    return(
        <div id="reportList" style={{
            /* minWidth: '60vw',  */
            /* width: (columnasParaMostrar.length > 0) ? widthTotal : 1550, */
            minWidth: (columnasParaMostrar.length > 0) ? widthTotal : 1850,
            paddingLeft: 10, 
            paddingRight: 10, 
            fontSize: 15, 
            maxHeight: '70vh',
            color: '#000'
            /* fontWeight: '600' */
            }}>
            <Grid 
                container 
                style={{
                    width: (columnasParaMostrar.length > 0) ? widthTotal : '100%',
                    borderBottomColor: '#ccc',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    position: 'relative',
                    /* overflowX: 'auto', */
                    height: 70,
                    backgroundColor: 'rgba(0, 0, 0, 0.08)'
                }}>
                {(admin && userData.isTest) && <IconButton 
                    style={{position: 'absolute', right: 0}}
                    aria-describedby={idPopover}
                    onClick={handleClickPopover}
                >
                    <FontAwesomeIcon icon={faEllipsisV} style={{width: 24, height: 24}} />
                </IconButton>}
                {openPopover && <PopoverSelectionColumn />}
                {
                    (columnasParaMostrar.length > 0 && admin)
                    ?
                    <>
                        {
                            columnasParaMostrar.map((columna, index) => {
                                return (
                                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px', padding: '0px 0px'}} key={index} xs={'auto'}>
                                        <p style={{textAlign: 'center', margin: '10px 0px', width: columna.width}}>
                                             <strong>{columna.name}</strong>
                                        </p>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px', padding: '0px 0px'}} xs={'auto'} >
                            <p style={{textAlign: 'left', margin: '10px 0px', width: '50px'}}>
                                <strong>Acciones</strong>
                            </p>
                        </Grid>
                    </>
                    :
                    <>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                        {admin && <Checkbox checked={allChecked} indeterminate={indeterminatedChecked} value={allItemsSelected} onChange={(e) => {setAllItemsSelected(indeterminatedChecked ? false : e.target.checked); indeterminatedChecked && setIndeterminatedChecked(false); indeterminatedChecked && setAllChecked(false)}} style={{marginTop: 5}} />}
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                        <p style={{textAlign: 'center', width: 50}}>
                            <strong>N° OT</strong>
                        </p>
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                        <p style={{textAlign: 'center', width: 70}}> <strong>Máquina</strong> </p>
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                        <p style={{textAlign: 'center', width: 60}}> <strong>Pauta</strong> </p>
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px', width: 102}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                        <p style={{textAlign: 'center', minWidth: 80}}> <strong>Flota</strong> </p>
                    </Grid>
                    {
                        (typeReportsSelected !== 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                        <p style={{textAlign: 'center', width: 80}}> <strong>Progreso</strong> </p>
                    </Grid>
                    }
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                        <p style={{textAlign: 'center', width: 100}}> <strong>Fecha <br /> Prevista</strong> </p>
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                        <p style={{textAlign: 'center', width: 100}}> <strong>Fecha <br /> Inicio</strong> </p>
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                        <p style={{textAlign: 'center', width: 100}}> <strong>Fecha <br /> Término</strong> </p>
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                        <p style={{textAlign: 'center', width: 120}}> <strong>Estado</strong> </p>
                    </Grid>
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                        <p style={{textAlign: 'center', width: 100}}> <strong>{(tipo === 'mantencion') ? 'OM SAP' : 'Aviso SAP'}</strong> </p>
                    </Grid>
                    {/*(typeReportsSelected !== 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                        <p style={{textAlign: 'center', width: 110}}> <strong>Responsable</strong> </p>
                    </Grid>*/}
                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                        <p style={{textAlign: 'left', width: 120, marginRight: 5, marginLeft: 5}}> <strong>Asignado a</strong> </p>
                    </Grid>
                    {(typeReportsSelected === 'En proceso' || typeReportsSelected === 'Por cerrar' || typeReportsSelected === 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                        <p style={{textAlign: 'left', width: 120, marginRight: 5, marginLeft: 5}}> <strong>Asignado por</strong> </p>
                    </Grid>}
                    {(typeReportsSelected === 'Por cerrar' || typeReportsSelected === 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                        <p style={{textAlign: 'left', width: 120, marginRight: 5, marginLeft: 5}}> <strong>Maquinaria revisor</strong> </p>
                    </Grid>}
                    {(typeReportsSelected === 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                        <p style={{textAlign: 'left', width: 120, marginRight: 5, marginLeft: 5}}> <strong>Ejec. SAP</strong> </p>
                    </Grid>}
                    {admin && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px', width: 60}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                        <p style={{textAlign: 'center', minWidth: 60}}> <strong>Obra</strong> </p>
                    </Grid>}
                    {(typeReportsSelected === 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px', width: 90}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                        <p style={{textAlign: 'center', minWidth: 60}}> <strong>Download</strong> </p>
                    </Grid>}
                    <Grid item style={{padding: '0px 5px', width: 80}} xs={1} sm={1} md={1} lg={'auto'} xl={'auto'} >
                        <p style={{textAlign: 'left'}}> <strong>Acción</strong> </p>
                    </Grid>
                    </>
                }
            </Grid>
            <div>
                {
                    (list && list.length === 0) && <Grid container>
                            <div style={{width: '100%', textAlign: 'center', height: '50vh'}}>
                            <img style={{margin: 0, top: '50%', left: 'calc(100%/1.53)'}} src={statusReports ? "../../assets/icons/Arrow.svg" : "../../assets/icons/Arrow.svg"} alt="" />
                            <div style={{width: '100%', textAlign: 'center', top: '55%'}}>
                                {
                                    statusReports
                                    ?
                                    <p>Descargando la información <br /> Espere un momento.</p>
                                    :
                                    <p>Selecciona otra opción <br /> El detalle no cuenta con lista de reportes.</p>
                                }
                            </div>
                        </div>
                    </Grid>
                }
            </div>
            
            {
                (columnasParaMostrar.length > 0 && admin)
                ?
                <div style={{height: 'calc(100vh - 400px)', width: (columnasParaMostrar.length > 0) ? widthTotal : '100%', overflow: 'auto'}}>
                    {
                        listaParaMostrar.map((item, index) => {
                            return (
                                <Grid container key={index} style={{borderBottom: '1px solid #ccc', width: 80}}>
                                    {
                                        columnasParaMostrar.map((col, i) => {
                                            let userType = false
                                            valuesAssignatiosn.forEach(value => {
                                                if (value === col.value) {
                                                    userType = true
                                                }
                                            })
                                            return (
                                                <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px', padding: '0px 10px', width: 80}} key={i} xs={'auto'} >
                                                    {
                                                        (typeof item[col.value] === 'string') && item[col.value].includes('http')
                                                        ?
                                                        <div style={{textAlign: 'center', width: col.width, padding: '12px 0px', width: 80}}>
                                                            <a href={item[col.value]}>Link</a>
                                                        </div>
                                                        :
                                                        (userType)
                                                        ?
                                                        ((usersFiltered(item[col.value]) !== null) && usersFiltered(item[col.value]).map((user, u)=> {
                                                            return (
                                                                <p key={u} style={{textAlign: 'left', width: col.width}}> {(user && user.name) ? `- ${user.name} ${user.lastName}` : user} </p>
                                                            )
                                                        })
                                                        
                                                        )
                                                        :
                                                        <p style={{textAlign: 'center', width: col.width}}> {(col.isDate) ? dateV2(item[col.value], 'dd/mm/yyyy') : item[col.value]} </p>
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}}>
                                        {
                                            item.origen
                                            ?
                                            <p style={{textAlign: 'center'}}> <button onClick={()=>{elevar(item)}} style={{backgroundColor: '#F9F9F9', borderRadius: 20, borderColor: '#757575', /* maxWidth: 130,  */height: 24, fontSize: 12}}>Elevar</button> </p>  
                                            :
                                            <p style={{textAlign: 'center'}}> <button onClick={()=>{openReviewModal(item)}} style={{backgroundColor: '#F9F9F9', borderRadius: 20, borderColor: '#757575', /* maxWidth: 130,  */height: 24, fontSize: 12}}>Ver</button> </p>  
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </div>
                :
                <div style={{height: 'calc(100vh - 430px)', width: '100%', overflow: 'auto'}}>
                {list && list.map((item, i) => {
                    return(
                        <Grid container key={i} style={{width: '100%', borderBottomColor: '#ccc', borderBottomStyle: 'solid', borderBottomWidth: 1}}>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                {admin && <Checkbox onChange={(e) => {selectItem(e, i)}} value={item.checked} checked={item.checked} style={{marginTop: 5}} />}
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                <p style={{textAlign: 'center', width: 50}}> {item.idIndex} </p>
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                                <p style={{textAlign: 'center', width: 70}}> {item.maquina && item.maquina.equ} </p>
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                <p style={{textAlign: 'center', width: 60}}> {item.guide === 'Pauta de Inspección' ? 'P.I.' : item.guide} </p>
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                                <p style={{textAlign: 'center', minWidth: 80, width: 91}}> {item.maquina && item.maquina.model} </p>
                            </Grid>
                            {
                                (typeReportsSelected !== 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                <p style={{textAlign: 'center', width: 80}}> {item.progress ? `${item.progress}%` : 'S/I'} </p>
                            </Grid>
                            }
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                                <p style={{textAlign: 'center', width: 100}}> {dateV2(item.datePrev, 'dd/mm/yyyy')} </p>
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                                <p style={{textAlign: 'center', width: 100}}> {dateV2(item.dateInit, 'dd/mm/yyyy')} </p>
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                                <p style={{textAlign: 'center', width: 100}}> {dateV2(item.endReport, 'dd/mm/yyyy')} </p>
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                <div style={{textAlign: 'center', width: 120}}>
                                    <p> <strong>{item.estado}</strong> </p>
                                </div>
                            </Grid>
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                <p style={{textAlign: 'center', width: 100}}> {item.sapId} </p>
                            </Grid>
                            {/*
                                (typeReportsSelected !== 'Completadas') && ((item.enabled) ? 
                                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                        <p style={{textAlign: 'center', width: 110}}> <button onClick={()=>{openModal(item)}} style={{backgroundColor: '#F9F9F9', borderRadius: 20, borderColor: '#757575', maxWidth: 130, height: 24, fontSize: 12}}>Asignar</button> </p>
                                    </Grid> :
                                    <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                        <p style={{textAlign: 'center', width: 110}}> <button disabled style={{backgroundColor: '#F9F9F9', borderRadius: 20, borderColor: '#757575', maxWidth: 130, height: 24, fontSize: 12, fontWeight: '800'}}>Terminado</button> </p>
                                    </Grid>
                                )
                            */}
                            <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                                <div style={{width: 120, marginRight: 5, marginLeft: 5}}>
                                <p key={i} style={{textAlign: 'left', minWidth: 60}}> {item.usersAssigned[0] ? `- ${item.usersAssigned[0].name} ${item.usersAssigned[0].lastName}` : '- Sin información'} </p>
                                {/* {
                                    usersFiltered(item.usersAssigned).map((user, i) => {
                                        return(<p key={i} style={{textAlign: 'left', minWidth: 60}}> {(user && user.name) ? `- ${user.name} ${user.lastName}` : user} </p>)
                                    })
                                } */}
                                </div>
                            </Grid>
                            {(typeReportsSelected === 'En proceso' || typeReportsSelected === 'Por cerrar' || typeReportsSelected === 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                                <div style={{width: 120, marginRight: 5, marginLeft: 5}}>
                                    <p key={i} style={{textAlign: 'left', minWidth: 60}}> {(item.historialAsignaciones[0]) ? `- ${usersFiltered(item.historialAsignaciones[0].asignadoPor)[0] && usersFiltered(item.historialAsignaciones[0].asignadoPor)[0].name} ${usersFiltered(item.historialAsignaciones[0].asignadoPor)[0] && usersFiltered(item.historialAsignaciones[0].asignadoPor)[0].lastName}` : '- Sin información'} </p>
                                </div>
                            </Grid>}
                            {(typeReportsSelected === 'Por cerrar' || typeReportsSelected === 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                                <div style={{width: 120, marginRight: 5, marginLeft: 5}}>
                                    <p key={i} style={{textAlign: 'left', minWidth: 60}}> {item.chiefMachineryApprovedBy ? `- ${usersFiltered(item.chiefMachineryApprovedBy)[0] && usersFiltered(item.chiefMachineryApprovedBy)[0].name} ${usersFiltered(item.chiefMachineryApprovedBy)[0] && usersFiltered(item.chiefMachineryApprovedBy)[0].lastName}` : '- Sin información'} </p>
                                </div>
                            </Grid>}
                            {(typeReportsSelected === 'Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
                                <div style={{width: 120, marginRight: 5, marginLeft: 5}}>
                                    <p key={i} style={{textAlign: 'left', minWidth: 60}}> {item.sapExecutiveApprovedBy ? `- ${usersFiltered(item.sapExecutiveApprovedBy)[0] && usersFiltered(item.sapExecutiveApprovedBy)[0].name} ${usersFiltered(item.sapExecutiveApprovedBy)[0] && usersFiltered(item.sapExecutiveApprovedBy)[0].lastName}` : '- Sin información'} </p>
                                </div>
                            </Grid>}
                            {
                                admin &&
                                
                                <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} style={{width: 60, borderRight: '1px #ccc solid', padding: '0px 5px', textAlign: 'center' }} >
                                    <Button aria-describedby={id} style={{textAlign: 'center', minWidth: 60, marginTop: 5}} onClick={(e) => {handleClick(e, item.site)}}> {item.site} </Button>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                        }}
                                    >
                                        <div style={{padding: 5}}><p>{siteName}</p></div>
                                    </Popover>
                                </Grid>
                            }
                            {(typeReportsSelected==='Completadas') && <Grid item style={{borderRight: '1px #ccc solid', padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}  >
                                {item.urlPdf ? <p style={{textAlign: 'center', width: 80}}><a href={`${item.urlPdf}`}>Link</a></p> : <p style={{textAlign: 'center', minWidth: 80}}> Sin URL </p>}
                            </Grid>}
                    
                            <Grid item style={{/* borderRight: '1px #ccc solid',  */padding: '10px 5px', width: 80}} xs={1} sm={1} md={1} lg={'auto'} xl={'auto'} >                                
                                <Grid container>
                                    <Grid item>
                                        {
                                            item.origen
                                            ?
                                            <button name='Elevar' title={`Elevar OT ${item.idIndex}`} onClick={()=>{elevar(item)}} style={{backgroundColor: '#F9F9F9', borderRadius: 20, borderColor: '#757575', height: 24, cursor: 'pointer'}}><FontAwesomeIcon icon={faArrowUp}/></button>
                                            :
                                            <button title={`Ver OT ${item.idIndex}`} onClick={()=>{openReviewModal(item)}} style={{backgroundColor: '#F9F9F9', borderRadius: 20, borderColor: '#757575', height: 24, fontSize: 12, cursor: 'pointer'}}><FontAwesomeIcon icon={faEye} /></button>
                                        }
                                    </Grid>
                                    {
                                        ((typeReportsSelected !== 'Completadas') && (item.enabled) && (!item.origen)) &&
                                            <Grid item style={{padding: '0px 5px'}} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                                <button title={`Asignar OT ${item.idIndex} a operario.`} onClick={()=>{openModal(item)}} style={{backgroundColor: '#F9F9F9', borderRadius: 20, borderColor: '#757575', maxWidth: 130, height: 24, fontSize: 12, cursor: 'pointer'}}>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </button>
                                            </Grid>
                                        
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })
                }
                </div>
            }
            {
                reportData && <AssignDialog open={openModalState} report={reportData} reportType={reportData.reportType} onlyClose={onlyClose} closeModal={closeModal}/>
            }
            {
                reportDataReview && <ReviewReportDialog open={openReviewModalState} report={reportDataReview} onlyClose={onlyCloseReview}/>
            }
        </div>
    )
}

export default ReportsList