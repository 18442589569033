import { ArrowBackIos } from "@mui/icons-material"
import { Box, Grid, Card, Toolbar, IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const DispositivosPage = () => {
    const navigate = useNavigate()
    return (
        <Box height='100%'>
            <Grid className={'root'} container spacing={0}>
                <Grid className={'pageContainer'} item xs={12}>
                    <Card elevation={0} className={'pageCard'}>
                        <div style={{width: '100%', textAlign: 'left', padding: 10 }}>
                            <div style={{width: '100%', textAlign: 'left', color: '#333', backgroundColor: '#fff', borderRadius: 20 }}>
                                <Toolbar style={{paddingLeft: 0, backgroundColor: '#F9F9F9', borderRadius: 10, position: 'relative'}}>
                                    <IconButton onClick={() => setTimeout(() => {
                                        navigate(-1)
                                    }, 500)}>
                                        <ArrowBackIos style={{color: '#333', fontSize: 16}}/> 
                                    </IconButton>
                                    <h1 style={{marginTop: 0, marginBottom: 0, fontSize: 16}}>
                                        Administración / Dispositivos
                                    </h1>
                                </Toolbar>
                            </div>
                        </div>
                        <div style={{width: '100%', textAlign: 'left', padding: 20, overflowY: 'auto', height: '100%'}}>
                            
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}