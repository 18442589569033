import React, { createContext, useContext, useEffect, useState } from 'react'
import { authRoutes, unidadesRoutes, usersRoutes } from '../routes'
import { dispositivosDatabase, imageDatabase, logsDatabase, machinesDatabase, pautasDatabase, reportsDatabase, userDatabase } from '../indexedDB'
import { useLocation, useNavigate } from 'react-router-dom'
import { FirmaUsuarioDialog } from '../dialogs'
import { useConnectionContext } from './Connection.context'
import { ConfirmarDialog } from '../dialogs/Confirmar.dialog'
import { crearDatosDispositivo } from '../routes/dispositivo.routes'
/* import DeviceInfo from 'react-native-device-info'; */


export const AuthContext = createContext()

export const AuthProvider = (props) => {
    const {isOnline} = useConnectionContext()
    const [ isAuthenticated, setIsAuthenticated ] = useState(false)
    const [ userData, setUserData ] = useState(JSON.parse(localStorage.getItem('user'))||undefined);
    const [ admin, setAdmin ] = useState(false);
    const [ roles, setRoles ] = useState([])
    const [ site, setSite ] = useState()
    const [ isOperator, setIsOperator ] = useState(false)
    const [ isSapExecutive, setIsSapExecutive ] = useState(false)
    const [ isShiftManager, setIsShiftManager ] = useState(false)
    const [ isChiefMachinery, setIsChiefMachinery ] = useState(false)
    const [ refCanvas, setRefCanvas ] = useState()
    const [ openSign, setOpenSign ] = useState(false)
    const [unidades, setUnidades] = useState([])
    const [permisos, indicarPermisos] = useState([])
    const [imagenesGenericasOfLine, setImagenesGenericasOfLine] = useState([])
    
    const [openConfirmar, setOpenConfirmar] = useState(false)
    const [mensajeConfirmar, setMensajeConfirmar] = useState('')

    const [isDraw, setIsDraw] = useState(false)

    const navigate = useNavigate()

    const location = useLocation()

    useEffect(() => {
        if (location && userData) {
            if (location.pathname) {
                const log = {
                    user: userData.name + ' ' + userData.lastName,
                    userId: userData._id,
                    id: Date.now(),
                    type: location.pathname
                }
                logsDatabase.agregar(log)
            }
        }
    }, [location.pathname, userData])

    useEffect(() => {
        if (window.localStorage.getItem('authV4')) {
            if (localStorage.getItem('isauthenticated')==='true') {
                setIsAuthenticated(true)
            }
        } else {
            setIsAuthenticated(false)
            navigate('/login', {replace:true})
            setTimeout(() => {
                setUserData(undefined)
                window.localStorage.removeItem('isauthenticated')
                window.localStorage.removeItem('user')
                window.localStorage.removeItem('authV4')
                window.localStorage.removeItem('unidades')
                window.localStorage.removeItem('isAdmin')
                pautasDatabase.borrarDb()
                reportsDatabase.borrarDb()
                window.localStorage.setItem('reloaded', 'ok')
                
                if (window.localStorage.getItem('reloaded') === 'ok') {

                } else {
                    setTimeout(() => {
                        window.location.href = window.location.href
                    }, 1000);
                }
            }, 1000);
        }
    }, [])

    useEffect(() => {
        if (imagenesGenericasOfLine.length === 0) {
            guardarImagenesLocales()
        }
    }, [imagenesGenericasOfLine])

    const guardarDatosDelDispositivo = async () => {
        try {
            const dataDispositivoEnBBDD = await dispositivosDatabase.consultar()
            console.log(dataDispositivoEnBBDD)
            if (dataDispositivoEnBBDD.length === 0) {
                const data = {
                    id: Date.now(),
                    gps: null,
                    online: true
                }
                const response = await crearDatosDispositivo(data)
                console.log(response)
                await dispositivosDatabase.agregar(response)
            } else {
                console.log(dataDispositivoEnBBDD[0])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const guardarImagenesLocales = async () => {
        const images = [
            {
                id: 0,
                name: 'noImage',
                path: './assets/no-image-icon-6.png',
                pathBase64: ''
            },
            {
                id: 1,
                name: 'noImageProfile',
                path: './assets/no-profile-image.png',
                pathBase64: ''
            }
        ]

        const {database} = await imageDatabase.initDb()
        images.forEach(async (imagen) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onloadend = async () => {
                    const imageCache = imagen
                    imageCache.pathBase64 = reader.result
                    imageDatabase.agregar(imageCache, database)
                    
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', imagen.path);
            xhr.responseType = 'blob';
            xhr.send();
        })
        setImagenesGenericasOfLine(images)
    }

    useEffect(() => {
        if (isAuthenticated) {
            guardarDatosDelDispositivo()
            Notification.requestPermission().then((res) => {
                if(res === 'denied' || res === 'default') {
                    
                }
            })
            if (!userData) {
                getUserDataFromIndexedDb()
            }
        } else {
            machinesDatabase.borrarDb()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated && userData) {
            getUnidades()
        }
    },[isAuthenticated, userData])

    useEffect(() => {
        if (unidades.length === 0) {
            if (localStorage.getItem('unidades')) {
                const unidadesCache = JSON.parse(localStorage.getItem('unidades'))
                setUnidades(unidadesCache)
            }
        }
    }, [unidades])

    const getUnidades = async () => {
        const response = await unidadesRoutes.getUnidades()
        localStorage.setItem('unidades', JSON.stringify(response.data.unidades))
        if (response.data.state) {
            setUnidades(response.data.unidades)
        }
    }

    useEffect(() => {
        if (userData) {
            if (!userData.sign || userData.sign.length < 1) {
                setTimeout(() => {
                    setOpenSign(true)
                }, 500)
            }
            if (userData.roles.length > 0) {
                let listaRoles = []
                listaRoles = userData.roles
                const permisosCache = []
                listaRoles.forEach(rol => {
                    if(rol === 'admin' || rol === 'superAdmin') {
                        localStorage.setItem('isAdmin', true);
                        permisosCache.push('admin')
                        setAdmin(true)
                    }
                    if(rol === 'inspectionWorker' || rol === 'maintenceOperator') {
                        localStorage.setItem('isOperator', true)
                        permisosCache.push('isOperator')
                        setIsOperator(true)
                    }
                    if(rol === 'sapExecutive') {
                        localStorage.setItem('isSapExecutive', true)
                        permisosCache.push('isSapExecutive')
                        setIsSapExecutive(true)
                    }
                    if(rol === 'shiftManager') {
                        localStorage.setItem('isShiftManager', true)
                        permisosCache.push('isShiftManager')
                        setIsShiftManager(true)
                    }
                    if(rol === 'chiefMachinery') {
                        localStorage.setItem('isChiefMachinery', true)
                        permisosCache.push('isChiefMachinery')
                        setIsChiefMachinery(true)
                    }
                })
                indicarPermisos(permisosCache)
            }
        } else {
            getUserDataFromIndexedDb()
        }
        if (userData && userData.obras.length > 0) {
            setSite(userData.obras[0])
            window.localStorage.setItem('sitio', JSON.stringify(userData.obras[0]))
        }
    },[userData])

    const activarConfirmacion = (message, select) => {
        setOpenConfirmar(true)
        setMensajeConfirmar(message)
        return (
            <ConfirmarDialog
                open={openConfirmar}
                message={mensajeConfirmar}
                /* select={select} */
                handleClose={() => {setOpenConfirmar(false)}}
            />
        )
    }

    const saveUserToDb = async (userDataLocal) => {
        const {database} = await userDatabase.initDb()
        await userDatabase.actualizar(userDataLocal, database)
    }

    const getUserDataFromIndexedDb = async () => {
        const {database} = await userDatabase.initDb()
        const response = await userDatabase.consultar(database)
        setUserData(response[0])
    }

    const setRefCanvasFunction = (ref) => {
        setRefCanvas(ref);
    }

    const clear = () => {
        refCanvas.clear();
    }

    const getImage = () => {
        console.log(refCanvas)
        if (isDraw) {
            usersRoutes.editUser({sign: refCanvas.toDataURL()}, userData._id).then(async () => {
                const userDataTemp = userData
                userDataTemp.sign = refCanvas.toDataURL()
                setUserData(userDataTemp)
                saveUserToDb(userDataTemp)
                localStorage.setItem('user', JSON.stringify(userDataTemp))
                setOpenSign(false);
                alert('Muchas gracias. Su firma ha sido actualizada.')
            })
        } else {
            alert('Por favor dibuje una firma.')
        }
        /*  */
    }

    const provider = {
        imagenesGenericasOfLine,
        permisos,
        userData,
        setUserData,
        isAuthenticated,
        admin,
        roles,
        site,
        setSite,
        isOperator,
        isSapExecutive,
        isShiftManager,
        isChiefMachinery,
        loginRut: (rut, password) => {
            return new Promise(resolve => {
                authRoutes.loginRut(rut, password)
                .then(async response => {
                    let userDataToSave = response.data
                    if(response.data.enabled) {
                        setRoles(userDataToSave.roles)
                        setUserData(userDataToSave)
                        const log = {
                            user: userDataToSave.name + ' ' + userDataToSave.lastName,
                            userId: userDataToSave._id,
                            id: Date.now(),
                            type: 'Login rut'
                        }
                        logsDatabase.agregar(log)
                        localStorage.setItem('user', JSON.stringify(userDataToSave))
                        window.localStorage.setItem('authV4', 'ok')
                        if (userDataToSave.role && userDataToSave.role.length > 0) {
                            if(userDataToSave.role === 'admin' || userDataToSave.role === 'superAdmin') {
                                localStorage.setItem('isAdmin', 'true')
                                setAdmin(true)
                            }else{
                                localStorage.setItem('isAdmin', 'false')
                                setAdmin(false);
                            }
                        }
                        localStorage.setItem('isauthenticated', 'true')
                        resolve({
                            state: true,
                            response: response
                        })
                        setIsAuthenticated(true)
                    } else {
                        if (response.data.message==='usuario no encontrado') {
                            localStorage.setItem('isauthenticated', 'false')
                            setIsAuthenticated(false)
                            alert('Usuario no encontrado.')
                            resolve({
                                state: false,
                            })
                        } else {
                            localStorage.setItem('isauthenticated', 'false')
                            setIsAuthenticated(false)
                            alert('Su cuenta no está habilitada, debe confirmarla.')
                            resolve({
                                state: false,
                            })
                        }
                    }
                })
                .catch(error => {
                    localStorage.setItem('isauthenticated', 'false')
                    alert((JSON.stringify(error).includes('400')) ? 'Contraseña incorrecta' : 'Error de autenticación: '+ error)
                    setIsAuthenticated(false)
                })
            })
        },
        login: (email, password) => {
            return new Promise(resolve => {
                authRoutes.login(email, password)
                .then(async response => {
                    let userDataToSave = response.data
                    if(response.data.enabled) {
                        setRoles(userDataToSave.roles)
                        setUserData(userDataToSave)
                        const log = {
                            user: userDataToSave.name + ' ' + userDataToSave.lastName,
                            userId: userDataToSave._id,
                            id: Date.now(),
                            type: 'Login email'
                        }
                        logsDatabase.agregar(log)
                        localStorage.setItem('user', JSON.stringify(userDataToSave))
                        window.localStorage.setItem('authV4', 'ok')
                        if (userDataToSave.role && userDataToSave.role.length > 0) {
                            if(userDataToSave.role === 'admin' || userDataToSave.role === 'superAdmin') {
                                localStorage.setItem('isAdmin', 'true')
                                setAdmin(true)
                            }else{
                                localStorage.setItem('isAdmin', 'false')
                                setAdmin(false);
                            }
                        }
                        localStorage.setItem('isauthenticated', 'true')
                        resolve({
                            state: true,
                            response: response
                        })
                        setIsAuthenticated(true)
                    } else {
                        if (response.data.message==='usuario no encontrado') {
                            localStorage.setItem('isauthenticated', 'false')
                            setIsAuthenticated(false)
                            alert('Usuario no encontrado.')
                            resolve({
                                state: false,
                            })
                        } else {
                            localStorage.setItem('isauthenticated', 'false')
                            setIsAuthenticated(false)
                            alert('Su cuenta no está habilitada, debe confirmarla.')
                            resolve({
                                state: false,
                            })
                        }
                    }
                })
                .catch(error => {
                    localStorage.setItem('isauthenticated', 'false')
                    alert((JSON.stringify(error).includes('400')) ? 'Contraseña incorrecta' : 'Error de autenticación: '+ error)
                    setIsAuthenticated(false)
                })
            })
        },
        logout: async () => {
            if(confirm('Confirme salida de la aplicación. Para volver a iniciar sesión requiere contar con internet para validar las credenciales.')) {
                const log = {
                    user: userData.name + ' ' + userData.lastName,
                    userId: userData._id,
                    id: Date.now(),
                    type: 'Logout'
                }
                logsDatabase.agregar(log)
                setTimeout(() => {
                    window.localStorage.clear()
                    navigate('/', {replace:true})
                    setIsAuthenticated(false)
                    pautasDatabase.borrarDb()
                    if (isOnline) {
                        window.location.reload()
                    }
                }, 500);
            }
        },
        unidades,
        activarConfirmacion
    }

    return (
        
        <>
        <AuthContext.Provider value={provider} {...props}/>
        <FirmaUsuarioDialog openSign={openSign} setRefCanvasFunction={setRefCanvasFunction} getImage={getImage} clear={clear} setIsDraw={setIsDraw} />
        </>
        
    )
}

export const useAuth = () => useContext(AuthContext)
