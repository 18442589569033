import { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css" 
import "slick-carousel/slick/slick-theme.css"
import { Button, IconButton, Checkbox, Grid, Hidden } from "@mui/material"
import CircleCheckedFilled from '@mui/icons-material/CheckCircle'
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import { faPen, faPaperclip, faCommentDots, faEye, faWrench } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { errorDatabase, executionReportsDatabase } from "../../indexedDB"
import { BacklogMessageDialog, LoadingLogoDialog, ReportDataDialog } from '../../dialogs'
import { useAuth, useConnectionContext, useExecutionReportContext } from "../../context"
import { azureStorageRoutes, executionReportsRoutes } from "../../routes"
import './pautaDetail.css'

const PautaDetail = (
    {
        height,
        reportAssigned,
        setProgress,
        resultThisItemProgress,
        canEdit,
        /* executionReport, */
        report,
        filterData
    }) => {
    const {isOnline} = useConnectionContext()
    const {userData, isOperator, unidades} = useAuth()
    const {actualizacionEjecucion, puedeCerrar, puedeRechazar, puedeTerminarJornada, executionReportBackboard, executionReport, setExecutionReport, saveExecutionReportToDb} = useExecutionReportContext()
    const [opened, setOpened] = useState(true)
    //Pestañas
    const [ gruposKeys, setGruposKeys ] = useState([])
    //Contenido de la vista
    const [ contentData, setContentData ] = useState([])
    const [ contentDataCache, setContentDataCache ] = useState([])
    //Lista de checks
    const [ checks, setChecks ] = useState([])
    //Indice de la actividad
    const [ indexActivity, setIndexActivity ] = useState()
    //
    const [ indexGroup, setIndexGroup ] = useState()
    const [indexContent, setIndexContent] = useState(0)
    //
    const [ openDialog1, setOpenDialog1 ] = useState(false)
    //
    const [ item, setItem ] = useState()
    const [ index, setIndex ] = useState()

    const [ loadingLogo, setLoadingLogo ] = useState(false)
    const [openDialogBacklog, setOpenDialogBacklog] = useState(false)
    const [revision, setRevision] = useState(false)

    const SampleArrow = (props) => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                style={{color: 'black', background: "grey", borderRadius: 20}}
                onClick={onClick}
            />
        )
    }

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow />
    }

    const settingsMovile = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        /* nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow /> */
    }

    useEffect(() => {
        setContentDataCache(contentData)
    }, [contentData])

    useEffect(() => {
        if (isOnline) {
            revisionDeDatos()
        }
    }, [isOnline])

    const returnContentByFiltering = (item) => {
        if (item) {
            if (filterData.todo) {
                return item
            } else if (filterData.backlogList) {
                if (item.backlogList && item.backlogList.length > 0) {
                    if (filterData.sinAccion && !filterData.ejecutado && !filterData.sinEjecutar) {
                        return filtroSinAcciones(item)
                    } else if (filterData.sinAccion && filterData.ejecutado && !filterData.sinEjecutar) {
                        return filtroSinAccionesEjecutado(item)
                    } else if (filterData.sinAccion && !filterData.ejecutado && filterData.sinEjecutar) {
                        return filtroSinAccionesNoEjecutado(item)
                    } else if (!filterData.sinAccion && filterData.ejecutado && !filterData.sinEjecutar) {
                        return filtroEjecutado(item)
                    } else if (!filterData.sinAccion && filterData.ejecutado && filterData.sinEjecutar) {
                        return filtroEjecutadoNoEjecutado(item)
                    } else if (!filterData.sinAccion && !filterData.ejecutado && filterData.sinEjecutar) {
                        return filtroNoEjecutado(item)
                    } else {
                        return item
                    }
                }
            } else {
                if (filterData.sinAccion && !filterData.ejecutado && !filterData.sinEjecutar) {
                    return filtroSinAcciones(item)
                } else if (filterData.sinAccion && filterData.ejecutado && !filterData.sinEjecutar) {
                    return filtroSinAccionesEjecutado(item)
                } else if (filterData.sinAccion && !filterData.ejecutado && filterData.sinEjecutar) {
                    return filtroSinAccionesNoEjecutado(item)
                } else if (!filterData.sinAccion && filterData.ejecutado && !filterData.sinEjecutar) {
                    return filtroEjecutado(item)
                } else if (!filterData.sinAccion && filterData.ejecutado && filterData.sinEjecutar) {
                    return filtroEjecutadoNoEjecutado(item)
                } else {
                    return filtroNoEjecutado(item)
                }
            }
        }
    }

    const filtroSinAcciones = (item) => {
        if (!item.isChecked) {
            return item
        }
    }

    const filtroSinAccionesEjecutado = (item) => {
        if (!item.isChecked || !item.isWarning) {
            return item
        }
    }

    const filtroSinAccionesNoEjecutado = (item) => {
        if (!item.isChecked || item.isWarning) {
            return item
        }
    }

    const filtroEjecutado = (item) => {
        if (item.isChecked) {
            if (!item.isWarning) {
                return item
            }
        }
    }

    const filtroNoEjecutado = (item) => {
        if (item.isChecked) {
            if (item.isWarning) {
                return item
            }
        }
    }

    const filtroEjecutadoNoEjecutado = (item) => {
        if (item.isChecked) {
            return item
        }
    }

    useEffect(() => {
        if (revision) {
            setRevision(false)
        } else {
            const newGroupData = []
            if (executionReportBackboard && executionReportBackboard._id) {
                Object.keys(executionReportBackboard.group).forEach((data, index) => {
                    let tieneBacklog = false
                    Object.values(executionReportBackboard.group[data]).forEach((d) => {
                        if (d.backlogList && d.backlogList.length > 0) {
                            tieneBacklog = true
                        }
                    })
                    let tab = {
                        data: data,
                        state: false,
                        tieneBacklog: tieneBacklog
                    }
                    if(index == indexContent) {
                        tab.state = true
                        setIndexGroup(data)
                    }
                    newGroupData.push(tab)
                })
                setGruposKeys(report.testMode ? [newGroupData[0], newGroupData[1], newGroupData[2]] : newGroupData)
                const groupsItem = []
                Object.values(executionReportBackboard.group)[indexContent].map(item => {
                    if(returnContentByFiltering(item)) {
                        groupsItem.push(item)
                    }
                })
                setContentData(groupsItem)
                setContentDataCache(Object.values(executionReportBackboard.group)[indexContent])
            }
        }
    },[executionReportBackboard, filterData, revision])

    useEffect(() => {
        if (contentDataCache && (executionReportBackboard && executionReportBackboard._id)) {
            if (unidades.length > 0) {
                const checked = []
                const contentDataGlobal = []
                const checkedGlobal = []
                contentDataCache.forEach((item) => {
                    if (item.isChecked) {
                        checked.push(item)
                    }
                    item.isRequerido = false
                    const unidadesFiltradas = unidades.filter(u => {if (u.idUnidad === item.unidad) return u})
                    if (unidadesFiltradas && unidadesFiltradas[0]) {
                        item.isRequerido = true
                        item.inputCantidad = 1
                        if (unidadesFiltradas && unidadesFiltradas[0] && unidadesFiltradas[0].inputRequerido==='TRUE') {
                            item.isRequerido = true
                            item.inputCantidad = unidadesFiltradas[0].inputCantidad
                        }
                    }
                })
                Object.values(executionReportBackboard.group).forEach((contentData) => {
                    contentData.forEach((item) => {
                        contentDataGlobal.push(item)
                        if (item.isChecked) {
                            checkedGlobal.push(item)
                        }
                    })
                })
                const progressPage = ((checked.length * 100) / contentDataCache.length).toFixed(0)
                const globalProgress = ((checkedGlobal.length * 100) / contentDataGlobal.length).toFixed(0)
                setProgress({
                    progressPage: progressPage,
                    globalProgress: globalProgress
                })
                setOpened(false)
            }
        }
    }, [contentDataCache, executionReportBackboard, gruposKeys, isOnline, unidades])

    const handleContent = (i, element) => {
        setIndexContent(i)
        setIndexGroup(element)
        const groupsItem = []
        Object.values(executionReport.group)[i].map(item => {
            if(returnContentByFiltering(item)) {
                groupsItem.push(item)
            }
        })
        setContentData(groupsItem)
        setContentDataCache(Object.values(executionReport.group)[i])
        const keysCache = [...gruposKeys]
        keysCache.forEach(key => {
            key.state = false
        })
        keysCache[i].state = true
        setGruposKeys(keysCache)
    }

    const openDialog = (item, index) => {
        setIndex(index)
        setItem(item)
        setOpenDialog1(true)
    }

    const openBacklogMessage = (item, index) => {
        setIndex(index)
        setItem(item)
        setOpenDialogBacklog(true)
    }

    const closeDialog = () => {
        setOpenDialog1(false)
        setItem(null)
    }

    const closeDialogBacklog = async (element, individual) => {
        setOpenDialogBacklog(false)
        setItem(null)
        if (individual) {
            setLoadingLogo(true)
        }
        let fileList
        if (element && element.backlogList && element.backlogList.length > 0) {
            element.backlogList.forEach(async (backlog, n) => {
                if (backlog.images && backlog.images.length > 0) {
                    if (isOnline) {
                        fileList = await Promise.all(backlog.images.map(async (file, z) => {
                            const fileUploaded  = await azureStorageRoutes.uploadImage(
                                file,
                                `${new Date().getFullYear()}/${report.idIndex}/${element.strpmdesc}/backlog/${Date.now()}_${z}`,
                                'reports-test'
                                );
                            const url = fileUploaded.data.data.url
                            return url
                        }))
                        backlog.fileList = fileList
                        backlog.images = null
                    }
                }
                if (n === (element.backlogList.length - 1)) {
                    const executionReportCache = executionReport
                    element.isChecked = true
                    element.isWarning = true
                    executionReportCache.group[indexGroup][index] = element
                    executionReportCache.offLineGuard = Date.now()
                    const {database} = await executionReportsDatabase.initDb()
                    await executionReportsDatabase.actualizar(executionReportCache, database)
                    if (isOnline) {
                        await executionReportsRoutes.saveExecutionReport(executionReportCache)
                    }
                    actualizacionEjecucion(Date.now())
                    setRevision(true)
                    if (individual) {
                        setLoadingLogo(false)
                    }
                }
            })
        }
    }

    const revisionDeDatos = () => {
        const contentDataCache = [...contentData]
        setLoadingLogo(true)
        contentDataCache.forEach((element, i) => {
            closeDialogBacklog(element, false)
        })
        setLoadingLogo(false)
    }

    const save = async (index, state, element) => {
        console.log('Guardando')
        const contentDataCacheTemp = [...contentDataCache]
        element.isChecked = true
        if (!element.historial) {
            element.historial = []
        }
        element.historial.push({
            tipo: 'guardado',
            id: Date.now(),
            fullName: `${userData.name} ${userData.lastName}`
        })
        element.isWarning = state ? false : true
        contentDataCacheTemp[index] = element
        const executionReportCache = executionReport
        executionReportCache.group[indexGroup] = contentDataCacheTemp
        executionReportCache.offLineGuard = Date.now()
        setExecutionReport(executionReportCache)
        setContentData(contentDataCacheTemp)
        setContentDataCache(contentDataCacheTemp)
        saveExecutionReportToDb(executionReportCache)
        actualizacionEjecucion(Date.now())
        if (isOnline) {
            await executionReportsRoutes.saveExecutionReport(executionReportCache)
        }
    } 

    const changeState = async (element, number) => {
        if (!element.isChecked) {
            if (element.isRequerido) {
                alert('Actividad requiere que deje un comentario.')
            } else if (element.unidad !== '*') {
                alert('Actividad requiere que deje una cantidad de insumo utilizado.')
            } else {
                if (confirm('¿Desea indicar estado ejecutado, sin dejar un mensaje?')) {
                    const messages = [
                        {
                            content: "Se indica estado ejecutado sin dejar mensajes",
                            id: Date.now(),
                            name: `${userData.name} ${userData.lastName}`,
                            namePicture: null,
                            urlBase64: undefined,
                            user: userData._id,
                        }
                    ]
                    element.isChecked = true
                    element.isWarning = false
                    element.messages = messages
                    save(number, true, element)
                }
            }
        } else {
            if (confirm('Confirme que desea desmarcar la tarea')) {
                const contentDataCacheTemp = [...contentData]
                contentDataCacheTemp[number].isChecked = false
                let n = 0
                contentDataCacheTemp[number].messages.forEach((message, index) => {
                    if (message === 'Se indica estado ejecutado sin dejar mensajes') {
                        n = index
                    }
                })
                contentDataCacheTemp[number].messages[n] = null
                setContentData(contentDataCacheTemp)
                setContentDataCache(contentDataCacheTemp)
                const executionReportCache = executionReport
                if (isOnline) {
                    await executionReportsRoutes.saveExecutionReport(executionReportCache)
                }
            }
        }
    } 

    return (
        <div style={{width: '100%'}}>
            {openDialogBacklog && <BacklogMessageDialog
                open={openDialogBacklog}
                item={contentData[index]}
                close={closeDialogBacklog}
                report={report}
            />}
            <Hidden mdDown>
                <div style={{height: 70, borderColor: '#ccc', borderWidth: 2, borderStyle: 'solid', borderRadius: 10}}>
                    <Slider {...settings}>
                        {
                            gruposKeys.map((element, index) => {
                                return (
                                    <div 
                                        key={index} 
                                        style={{
                                        textAlign: 'center', 
                                        height: '100%'}}>
                                            <Button style={{borderRadius:10, marginTop: 2.5, border: element.tieneBacklog ? '#BB2D2D 1px solid' : null, textAlign: 'center', width: '90%', height: 60}} onClick={() => handleContent(index, element.data/* gruposKeys, element */)}>
                                                <p style={{margin: 0}}>{element.data}</p>
                                            </Button>
                                        {
                                            element.state && <div style={{width: '90%', height: 6, backgroundColor: '#BB2D2D', position: 'relative', marginLeft: '5%', bottom: 5, borderRadius: 5}}>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </Hidden>
            <Hidden mdUp>
                <div style={{borderColor: '#ccc', borderWidth: 2, borderStyle: 'solid', borderRadius: 10, width: '100%'}}>
                    <Slider {...settingsMovile}>
                        {
                            gruposKeys.map((element, index) => {
                                return (
                                    <div 
                                        key={index} 
                                        style={{
                                        textAlign: 'center', 
                                        height: '100%'}}>
                                            <Button style={{borderRadius:10, border: element.tieneBacklog ? '#BB2D2D 1px solid' : null, textAlign: 'center', width: '90%', height: 60}} onClick={() => handleContent(index, element.data/* gruposKeys, element */)}>
                                                <p style={{margin: 0}}>{element.data}</p>
                                            </Button>
                                        {
                                            element.state && <div style={{width: '90%', height: 6, backgroundColor: '#BB2D2D', position: 'relative', marginLeft: '5%', bottom: 5, borderRadius: 5}}>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </Hidden>
            {report && <div style={{position: 'relative'}}>
                <Grid container>
                    <Grid item SampleArrow={'auto'}>
                        <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: 30}}> <strong>N°</strong> </p>
                    </Grid>
                    <Grid item sm={1}>
                        <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: '100%'}}> <strong>Personal <br /> Necesario</strong> </p>
                    </Grid>
                    <Grid item sm={'auto'}>
                        <div style={{width: 150}}>
                            <p style={{fontSize: 12, margin: 0, textAlign: 'center'}}> <strong>Descripcion De Tarea</strong> </p>
                        </div>
                    </Grid>
                    <Grid item sm={3} md={2}>
                        <p style={{fontSize: 12, margin: 0, textAlign: 'center'}}> <strong>Observaciones</strong> </p>
                    </Grid>
                    <Hidden mdDown>
                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                            <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: 100}}> <strong>N° Parte <br /> a Utilizar</strong> </p>
                        </Grid>}
                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                            <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: 100}}> <strong>Cantidad <br /> a utilizar</strong> </p>
                        </Grid>}
                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                            <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: 60}}> <strong>Cantidad <br /> Utilizada</strong> </p>
                        </Grid>}
                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                            <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: 60}}> <strong>Tipo <br /> Rpto</strong> </p>
                        </Grid>}
                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                            <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: 100}}> <strong>Parámetro <br /> a Medir</strong> </p>
                        </Grid>}
                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                            <p style={{fontSize: 12, margin: 0, textAlign: 'center', width: 60}}> <strong>Parámetro <br /> Obtenido</strong> </p>
                        </Grid>}
                    </Hidden>
                    <Grid item sm={1}>
                        <p style={{fontSize: 12, margin: 0, marginLeft: 5, textAlign: 'left'}}> <strong>Acción</strong> </p>
                    </Grid>
                    <Grid item sm={1}>
                        <p style={{fontSize: 12, margin: 0, marginLeft: 5, textAlign: 'left'}}> <strong>Estado</strong> </p>
                    </Grid>
                </Grid>
                <div style={{height: height, overflowY: 'auto'}}>
                    {
                        contentData.map((e, n) => {
                            if (!e.isChecked) {
                                e.isChecked = false
                            }
                            return(
                                <Grid key={n} container style={{borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: '#ccc'}}>
                                    <Grid item xs={1} md={'auto'}>
                                        <div style={{width: 30}}>
                                            <p style={{fontSize: 12, margin: 0, textAlign: 'center'}}> <strong>{n+1}.-</strong> </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={11} md={1}>
                                        <div style={{width: '100%'}}>
                                            <p style={{fontSize: 12, margin: 0, textAlign: 'center', wordBreak: 'break-word'}}> {e.workteamdesc}   </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={'auto'}>
                                        <div style={{width: 150}}>
                                            <p style={{fontSize: 12, margin: 0, textAlign: 'left'}}> {e.taskdesc} </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <div style={{width: '100%'}}>
                                            <p style={{fontSize: 12, margin: 0, textAlign: 'justify'}}>
                                                {
                                                    e.obs01
                                                    .replace('____________________________________.', '________.')
                                                    .replace('__________________________', '________')
                                                    .replace('_________________', '________')
                                                }
                                            </p>
                                        </div>
                                    </Grid>
                                    {/* N° Parte */}
                                    <Hidden mdDown>
                                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                                            {
                                            (e.partnumberUtl === '*' || ( 
                                                e.unidad === '%'|| 
                                                e.unidad === '°C' || 
                                                e.unidad === 'CM' || 
                                                e.unidad === 'H' || 
                                                e.unidad === 'KM' || 
                                                e.unidad === 'KPA' ||
                                                e.unidad === 'M' || 
                                                e.unidad === 'MM' || 
                                                e.unidad === 'N' || 
                                                e.unidad === 'PSI' || 
                                                e.unidad === 'RPM' || 
                                                e.unidad === 'T' || 
                                                e.unidad === 'V'
                                            )) ? 
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 100}}>
                                                N/A
                                            </p>
                                            :
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 100}}>
                                                {e.partnumberUtl}
                                            </p>
                                            }
                                        </Grid>}
                                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                                            {
                                            ( 
                                                e.unidad === 'UN'|| 
                                                e.unidad === 'C/U' || 
                                                e.unidad === 'BL' || 
                                                e.unidad === 'LT' || 
                                                e.unidad === 'KG' || 
                                                e.unidad === 'JG' ||
                                                e.unidad === 'GLN'
                                            ) ? 
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 100}}>
                                                {e.cantidad} {e.unidad}
                                            </p>                                            
                                            :
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 100}}>
                                                N/A
                                            </p>
                                            }
                                        </Grid>}
                                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                                            {
                                            ( 
                                                e.unidad === 'UN'|| 
                                                e.unidad === 'C/U' || 
                                                e.unidad === 'BL' || 
                                                e.unidad === 'LT' || 
                                                e.unidad === 'KG' || 
                                                e.unidad === 'JG' ||
                                                e.unidad === 'GLN'
                                            ) ? 
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 60}}>
                                                {e.unidadData ? e.unidadData : '___'} {e.unidad}
                                            </p>
                                            :
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 60}}>
                                                N/A
                                            </p>
                                            }
                                        </Grid>}
                                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                                            {
                                            (e.idtypeutlPartnumber === '*') ? 
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 60}}>
                                                N/A
                                            </p>
                                            :
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 60}}>
                                                {e.idtypeutlPartnumber}
                                            </p>
                                            }
                                        </Grid>}
                                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                                            {
                                            ( 
                                                e.unidad === '%'|| 
                                                e.unidad === '°C' || 
                                                e.unidad === 'CM' || 
                                                e.unidad === 'H' || 
                                                e.unidad === 'KM' || 
                                                e.unidad === 'KPA' ||
                                                e.unidad === 'M' || 
                                                e.unidad === 'MM' || 
                                                e.unidad === 'N' || 
                                                e.unidad === 'PSI' || 
                                                e.unidad === 'RPM' || 
                                                e.unidad === 'T' || 
                                                e.unidad === 'V'
                                            ) ?
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 100}}>
                                                {e.partnumberUtl}
                                            </p>
                                            :
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 100}}>
                                                N/A
                                            </p>
                                            }
                                        </Grid>}
                                        {(report.reportType === 'Mantención') && <Grid item xl={'auto'} md={'auto'}>
                                            {
                                                ( 
                                                    e.unidad === '%'|| 
                                                    e.unidad === '°C' || 
                                                    e.unidad === 'CM' || 
                                                    e.unidad === 'H' || 
                                                    e.unidad === 'KM' || 
                                                    e.unidad === 'KPA' ||
                                                    e.unidad === 'M' || 
                                                    e.unidad === 'MM' || 
                                                    e.unidad === 'N' || 
                                                    e.unidad === 'PSI' || 
                                                    e.unidad === 'RPM' || 
                                                    e.unidad === 'T' || 
                                                    e.unidad === 'V'
                                                ) ? 
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 60}}>
                                                {e.unidadData ? e.unidadData : '___'} {e.unidad}
                                            </p>
                                            :
                                            <p style={{fontSize: 12, padding: 5, margin: 0, textAlign: 'center', width: 60}}>
                                                N/A
                                            </p>
                                            }
                                        </Grid>}
                                    </Hidden>
                                    <Grid item xs={6} sm={6} md={1}>
                                        <Grid container >
                                            <Grid item xl={4} style={{textAlign:'left', paddingLeft: 5}}>
                                                <IconButton onClick={()=>{openDialog(e, n); setIndexActivity(n)}}>
                                                    <FontAwesomeIcon style={{fontSize: 20}} icon={(canEdit || (isOperator && (userData._id === report.usersAssigned[0]._id))) ? faPen : faEye}/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xl={4} style={{textAlign:'left', paddingLeft: 5}}>
                                                <IconButton style={{backgroundColor: (e.backlogList && e.backlogList.length > 0) ? 'red' : 'transparent'}} onClick={()=>{openBacklogMessage(e, n); setIndexActivity(n)}}>
                                                    <FontAwesomeIcon style={{fontSize: 20}} icon={faWrench} color={(e.backlogList && e.backlogList.length > 0) ? "white" : 'rgba(0, 0, 0, 0.54)'}/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xl={4} style={{textAlign:'left'}}>
                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={1}>
                                        {
                                            !puedeTerminarJornada && (e.isChecked ? 
                                            <Checkbox 
                                            checked={e.isChecked} 
                                            disabled
                                            style={{transform: "scale(1.2)"}} 
                                            icon={<CircleUnchecked style={{fontSize: 20}} />} 
                                            checkedIcon={<CircleCheckedFilled style={{fontSize: 20, color: e.isWarning ? '#EAD749' : '#27AE60'}} />} 
                                            />
                                        :
                                            <Checkbox 
                                            checked={e.isChecked} 
                                            disabled 
                                            style={{transform: "scale(1.2)"}} 
                                            icon={<CircleUnchecked style={{fontSize: 20}} />} 
                                            checkedIcon={<CircleCheckedFilled style={{fontSize: 20, color: e.isWarning ? '#EAD749' : '#27AE60'}} />} 
                                            />)
                                        }
                                        {
                                            puedeTerminarJornada && (e.isChecked ? 
                                                <Checkbox 
                                                value={e.isChecked}
                                                checked={e.isChecked} 
                                                onClick={() => { changeState(e, n) }} 
                                                style={{transform: "scale(1.2)"}} 
                                                icon={<CircleUnchecked style={{fontSize: 20}} />} 
                                                checkedIcon={<CircleCheckedFilled style={{fontSize: 20, color: e.isWarning ? '#EAD749' : '#27AE60'}} />} 
                                                />
                                            :
                                                <Checkbox 
                                                value={e.isChecked}
                                                checked={e.isChecked} 
                                                onClick={() => { changeState(e, n) }} 
                                                style={{transform: "scale(1.2)"}} 
                                                icon={<CircleUnchecked style={{fontSize: 20}} />} 
                                                checkedIcon={<CircleCheckedFilled style={{fontSize: 20, color: e.isWarning ? '#EAD749' : '#27AE60'}} />} 
                                                />)
                                        }
                                        {e.haveMessage && <IconButton style={{padding: 1}} disabled><FontAwesomeIcon style={{fontSize: 20}} icon={faCommentDots} /></IconButton>}
                                        {!e.messages && <IconButton style={{padding: 1}} disabled><FontAwesomeIcon style={{fontSize: 20, color: 'transparent'}} icon={faCommentDots} /></IconButton>}
                                        {e.haveClip && <IconButton style={{padding: 1}} disabled><FontAwesomeIcon style={{fontSize: 20}} icon={faPaperclip} /></IconButton>}
                                        {!e.haveClip && <IconButton style={{padding: 1}} disabled><FontAwesomeIcon style={{fontSize: 20, color: 'transparent'}} icon={faPaperclip} /></IconButton>}
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </div>
                {openDialog1 && <ReportDataDialog 
                    open={openDialog1} 
                    handleClose={closeDialog} 
                    report={reportAssigned} 
                    item={item} 
                    index={index} 
                    executionReport={executionReport} 
                    gruposKeys={gruposKeys} 
                    indexActivity={indexActivity} 
                    indexGroup={indexGroup} 
                    save={save}
                    setChecks={setChecks}
                    canEdit={canEdit}
                />}
                {
                    loadingLogo && <LoadingLogoDialog open={loadingLogo} />
                }
            </div>}
        </div>
    )
}

export default PautaDetail

