const initDbDevices = () => {
    const indexedDb = window.indexedDB;

    const conexion = indexedDb.open('Device', 1)

    let db

    return new Promise(resolve => {
        conexion.onsuccess = () =>{
            db = conexion.result
            resolve(
                {
                    message: "Base de datos abierta",
                    database: db,
                    state: true
                }
            )
        }
        conexion.onupgradeneeded = (e) =>{
            db = e.target.result;
            const coleccionObjetos = db.createObjectStore('Devices',{
                keyPath: '_id'
            })

            coleccionObjetos.transaction.oncomplete = (event) => {
                resolve(
                    {
                        message: "Base de datos creada / actualizada",
                        database: db,
                        state: true
                    }
                )
            }
        }
        conexion.onerror = (error) =>{
            resolve(
                {
                    message: "Error",
                    error: error,
                    state: false
                }
            )
        }
    })
}

const agregar = async (data) => {
    const {database} = await initDbDevices()
    return new Promise(resolve => {
        const trasaccion = database.transaction(['Devices'],'readwrite')
        const coleccionObjetos = trasaccion.objectStore('Devices')
        const conexion = coleccionObjetos.add(data)
        conexion.onsuccess = () =>{
            resolve(true)
        }
        conexion.onerror = (err) => {
            resolve(false)
        }
    })
}

const obtener = async (clave) =>{
    const {database} = await initDbDevices()
    return new Promise(resolve => {
        const trasaccion = database.transaction(['Devices'],'readonly')
        const coleccionObjetos = trasaccion.objectStore('Devices')
        const conexion = coleccionObjetos.get(clave)

        conexion.onsuccess = (e) =>{
            resolve(e.target.result)
        }
    })    
}

const actualizar = async (data) =>{
    const {database} = await initDbDevices()
    return new Promise(resolve => {
        try {
            const trasaccion = database.transaction(['Devices'],'readwrite')
            const coleccionObjetos = trasaccion.objectStore('Devices')
            const conexion = coleccionObjetos.put(data)
            
            conexion.onsuccess = () =>{
                resolve(true)
            }

            conexion.onerror = (err) => {
                console.log(err) 
                resolve(true)
            }
        
        } catch (err) {
            console.log(err) 
            resolve(false)
        } 
    }) 
}

const eliminar = async (clave) =>{
    const {database} = await initDbDevices()
    return new Promise(resolve => {
        const trasaccion = database.transaction(['Devices'],'readwrite')
        const coleccionObjetos = trasaccion.objectStore('Devices')
        const conexion = coleccionObjetos.delete(clave)

        conexion.onsuccess = () =>{
            resolve(true)
        }

        conexion.onerror = () => {
            resolve(false)
        }
    })
}

const removerTodo = async (databaseName) => {
    return new Promise(resolve => {
        let req = indexedDB.deleteDatabase(databaseName)
        req.onsuccess = function () {
            resolve(true)
        };
        req.onerror = function () {
            resolve(false)
        };
        req.onblocked = function () {
            resolve(false)
        };
    })
}

const consultar = async () =>{
    const {database} = await initDbDevices()
    const trasaccion = database.transaction(['Devices'],'readonly')
    const coleccionObjetos = trasaccion.objectStore('Devices')
    const conexion = coleccionObjetos.openCursor()
    
    return new Promise(resolve => {
        conexion.onsuccess = (e) =>{
            const cursor = e.target.result;
            const allObject = coleccionObjetos.getAll()
            allObject.onsuccess = (ev) => {
                resolve(ev.target.result)
            }
        }
    })
}

export default {
    initDbDevices,
    agregar,
    obtener,
    actualizar,
    eliminar,
    removerTodo,
    consultar
}
