import { useState } from 'react';
import { Button, Dialog, Grid, IconButton, Slide } from "@mui/material"
import { Close } from "@mui/icons-material"
import { forwardRef } from "react";
import { useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PDFFileDialog = ({open, handleClose, file}) => {

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const [pageWidth, setPageWidth] = useState()
  
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        const containerPdf = document.getElementById('container-pdf')
        if (containerPdf) {
            setPageWidth(containerPdf.offsetWidth)
        }
    }

    useEffect(() => {
        console.log(file)
    }, [file])

    /* useEffect(() => {
        const containerPdf = document.getElementById('container-pdf')
        containerPdf.onload = (e) => {
            console.log(containerPdf.offsetWidth)
        }
        if (containerPdf) {
            console.log(containerPdf.offsetWidth)
        }
    }, []) */
  
    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <IconButton
                    style={{position: 'absolute', top: 20, right: 20, backgroundColor: 'transparent', color: '#ccc', zIndex: 100}}
                    onClick={handleClose}
                    >
                    <Close />
                </IconButton>
                <div style={{ position: 'relative', padding: '30px 5px', textAlign: 'center', width: '100%', height: 'calc(100vh - 10px)'}}>
                    <Grid container>
                        <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
                        <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
                            <div id={'container-pdf'} style={{height: 'calc(100vh - 100px)', width: '100%', overflowY: 'auto'}}>
                                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page width={pageWidth && (pageWidth - 10)} pageNumber={pageNumber} />
                                </Document>
                            </div>
                        </Grid>
                        <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
                    </Grid>
                    <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%', textAlign: 'center'}}>
                        <p>
                            <button disabled={pageNumber === 1} onClick={() => {setPageNumber(pageNumber - 1)}}>{'<'}</button> Page {pageNumber} of {numPages} 
                            <button disabled={pageNumber === numPages} onClick={() => {setPageNumber(pageNumber + 1)}}>{'>'}</button>
                        </p>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default PDFFileDialog