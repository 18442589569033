import React, { useState, useEffect } from 'react'
import { Box, Card, Grid } from '@mui/material'
import { CardButton } from '../../components/buttons'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { useAuth, useTimeContext } from '../../context'
import { useNotificationsContext } from '../../context/Notifications.context'

const WelcomePage = () => {
    const {admin, isOperator, isSapExecutive, isShiftManager, isChiefMachinery, userData} = useAuth()
    const {date, hour} = useTimeContext()
    const {lastNotification, ultimaNoticia, listaLectura} = useNotificationsContext()
    const [ disableButtonNoSAP, setDisableButtonsNoSAP ] = useState(true)
    const [ disableButtonNoAdmin, setDisableButtonNoAdmin ] = useState(true)

    useEffect(() => {
        if (admin || isSapExecutive || isShiftManager || isChiefMachinery) {
            setDisableButtonsNoSAP(false)
        }
        if (admin) {
            setDisableButtonNoAdmin(false)
        }
    }, [admin, isOperator, isSapExecutive, isShiftManager, isChiefMachinery])

    const navigate = useNavigate()

    const addLog = (variant) => {
        const log = {
            user: userData.name + ' ' + userData.lastName,
            userId: userData._id,
            id: Date.now(),
            type: `Open ${variant}`
        }
        logsDatabase.agregar(log)
    }

    return (
        <Box height='100%'>
            <Grid className={'root'} container spacing={0}>
                <Grid className={'pageContainer'} item xs={12}>
                    <Card elevation={0} className={'pageCard'} >
                        <div>
                            <p className='titulo'>Hola {userData.name} ¿Por dónde quieres comenzar hoy?</p>
                        </div>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={(admin||
                                isSapExecutive||
                                isShiftManager||
                                isChiefMachinery) ? 9 : 12}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <div className='notificaciones reloj'>
                                            <p className='reloj-fecha'> {date} </p>
                                            <p className='reloj-hora'> {hour} hrs </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <button className='notificaciones alertas' onClick={() => navigate('/notifications')}>
                                            <p className='notificaciones-texto'> <b>Última Notificación:</b> </p>
                                            <p className='notificaciones-texto'> {lastNotification && lastNotification.message} </p>
                                        </button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <button className='notificaciones alertas' onClick={() => navigate('/mynews')}>
                                            <div>
                                                <p style={{marginTop: 5}}>Noticias internas</p>
                                                <p className='notificaciones-texto'> {
                                                listaLectura ? 
                                                (ultimaNoticia ? 
                                                ultimaNoticia.titulo : 'No tiene noticias en su muro') : 'Cargando noticias en su muro'} </p>
                                            </div>
                                        </button>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <CardButton variant='assignment' disableButton={false}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <CardButton variant='machines' disableButton={false}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <CardButton variant='reports' disableButton={disableButtonNoSAP}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <CardButton variant='administration' disableButton={disableButtonNoAdmin}/>
                                    </Grid>  
                                </Grid>
                            </Grid>
                            {(admin||
                                isSapExecutive||
                                isShiftManager||
                                isChiefMachinery) && <Grid item xs={12} sm={12} md={12} lg={3}>
                                <Grid container /* spacing={5} */ style={{height: '100%'}}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CardButton variant='calendar' disableButton={false}/>
                                    </Grid>
                                </Grid>
                            </Grid> } 
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default WelcomePage
