import { createContext, useContext, useEffect, useState } from "react";
import { errorDatabase, executionReportsDatabase, pautasDatabase, reportsDatabase } from "../indexedDB";
import { useAuth, useConnectionContext, useReportsContext } from ".";
import { executionReportsRoutes, patternsRoutes, pdfMakeRoutes, reportsRoutes } from "../routes";
import { useLocation, useNavigate } from "react-router-dom";
import { LianearProgresDialog, ReportCommitDialog, ReportMessagesDialog } from "../dialogs";
import { detectIf3DModelExist, getExecutivesSapEmail, saveReport, translateSubSystem } from "../config";
import { useLoadingContext } from "./Loading.context";
import { BacklogsResume } from "../dialogs/BacklogsResume.dialog";
import { SocketConnection } from "../connections";

export const ExecutionReportContext = createContext()

export const ExecutionReportProvider = (props) => {
    const {admin, isOperator, isSapExecutive, isShiftManager, isChiefMachinery, userData, site, activarConfirmacion} = useAuth()
    const {setLoadingLogo} = useLoadingContext()
    const {isOnline} = useConnectionContext()
    const {reports, setMessage, getReportsOffline, saveReportToData, getReports} = useReportsContext()
    const [report, setReport] = useState()
    const [executionReport, setExecutionReport] = useState()
    const [executionReportBackboard, setExecutionReportBackboard] = useState()
    const [reportId, setReportId] = useState()
    const [otIndex, setOtIndex] = useState()
    const [sapId, setSapId] = useState()
    const [serieEquipo, setSerieEquipo] = useState()
    const [modoTest, setModoTest] = useState(true)
    const [avanceHoja, setAvanceHoja] = useState(0)
    const [avanceTotal, setAvanceTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('')
    const [reporteIniciado, setReporteIniciado] = useState(true)
    const [openLinear, setOpenLinear] = useState(false)
    const [percentDownload, setPercentDownload] = useState(0)
    const [messageOnReport, setMessageOnReport] = useState('')
    const [ejecucionActualizada, actualizacionEjecucion] = useState(0)
    const [messageBacklog, setMessageBacklog] = useState('')
    const [machineData, setMachineData ] = useState()
    const [habilite3D, setHabilite3D] = useState(false)
    const [ puedeEnviar, setPuedeEnviar ] = useState(false)
    const [ puedeCerrar, setPuedeCerrar ] = useState(false)
    const [ puedeRechazar, setPuedeRechazar ] = useState(false)
    const [ puedeTerminarJornada, setPuedeTerminarJornada ] = useState(false)
    const [materialesPreview, setMaterialesPreview] = useState()
    const [totalBacklogs, setTotalBacklogs] = useState(0)
    const [ progress, resutProgress ] = useState(0)
    const [ itemProgress, resultThisItemProgress ] = useState(0)
    const [isTermJornada, setIsTermJornada] = useState(false)
    const [ openReportCommitModal, setOpenReportCommitModal ] = useState(false)
    const [ messageType, setMessageType ] = useState()
    const [ openMessagesModal, setOpenMessagesModal ] = useState(false)
    const [contenidoResumenBacklog, setContenidoResumenBacklog] = useState()
    const [openResumeBacklogsDialog, setOpenResumeBacklogsDialog] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [toForward, setToForward] = useState(false)
    const [ejecucion, setEjecucion] = useState({
        inicio: null,
        termino: null,
        userId: null
    })


    const [recovery, setRecovery] = useState(false)

    const navigate = useNavigate()

    const location = useLocation()

    useEffect(() => {
        if (!openLinear) {
            setPercentDownload(0)
        }
    }, [openLinear])

    useEffect(() => {
        if (!location.pathname.includes('assignment/')) {
            setReporteIniciado(true)
            setExecutionReport(undefined)
            setExecutionReportBackboard(undefined)
            setReport(undefined)
            setReportId(undefined)
            setOtIndex(undefined)
            setSapId(undefined)
            setSerieEquipo(undefined)
            setModoTest(true)
        }
    }, [location])

    useEffect(() => {
        if (report) {
            report.progress = itemProgress
            saveReport(report)
            saveReportToData(report)
        }
    }, [itemProgress])

    useEffect(() => {
        if (executionReport && isOnline) {
            console.log('Se actualiza ejecución')
            
        }
    }, [executionReport, isOnline])

    const saveExecutionReportToDb = async (executionReportCache) => {
        console.log(executionReportCache)
        const {database} = await executionReportsDatabase.initDb()
        await executionReportsDatabase.actualizar(executionReportCache, database)
    }

    useEffect(() => {
        if (report) {
            setReportId(report._id)
            setOtIndex(report.idIndex)
            setSapId(report.sapId)
            setSerieEquipo(report.machine)
            setModoTest(report.testMode)
            setMessage('Buscando Pauta')
            getExecutionReport()
            updateReportDBIndex()
        }
    },[report])

    useEffect(() => {
        if (otIndex) {
            getReportFromOtIndex()
        }
    },[otIndex])

    useEffect(() => {
        const puedeEnviarCache = permisosEnviar()
        setPuedeEnviar(puedeEnviarCache)
        const puedeCerrarCache = permisosCerrar()
        setPuedeCerrar(puedeCerrarCache)
        const puedeRechazarCache = permisosRechazar()
        setPuedeRechazar(puedeRechazarCache)
        const puedeTerminarJornadaCache = permisosTerminarJornada()
        setPuedeTerminarJornada(puedeTerminarJornadaCache)
    }, [report, isOperator, isSapExecutive, isShiftManager, isChiefMachinery])

    

    useEffect(() => {
        if (executionReport) {
            const group = executionReport.group
            let totalBacklogsCache = 0
            Object.keys(group).forEach(key => {
                group[key].forEach(item => {
                    if (item.backlogList && (item.backlogList.length > 0)) {
                        totalBacklogsCache = totalBacklogsCache + item.backlogList.length
                    }
                })
            })
            setTotalBacklogs(totalBacklogsCache)
            /* if (isOnline) {
                guardarEnCloud()
            } */
        }
    },[executionReport])

    /* const guardarEnCloud = async () => {
        const response = await executionReportsRoutes.saveExecutionReport(executionReport)
        console.log(response)
    } */

    const permisosEnviar = () => {
        if (report) {
            if (isOperator && (!report.level || report.level === 0)) {
                return true
            } else if ((isShiftManager) && (report.level === 1)) {
                return true
            } else if ((isChiefMachinery) && (report.level === 2)) {
                return true
            } else {
                return false
            }
        }
    }
    
    const permisosTerminarJornada = () => {
        if (report) {
            if (isOperator && (!report.level || report.level === 0)) {
                return true
            } else {
                return false
            }
        }
    }

    const permisosRechazar = () => {
        if (report) {
            if ((isShiftManager) && (report.level === 1)) {
                return true
            } else if ((isChiefMachinery) && (report.level === 2)) {
                return true
            } else {
                return false
            }
        }
    }

    const permisosCerrar = () => {
        if (report) {
            if ((isSapExecutive || admin) && (report.level === 3)) {
                return true
            } else {
                return false
            }    
        }
    }

    const updateReportDBIndex = async () => {
        const {database} = await reportsDatabase.initDbReports()
        await reportsDatabase.actualizar(report, database)
    }

    const getReportFromOtIndex = async () => {
        try {
            const reportFiltered = reports.filter(r => {if(r.idIndex === Number(otIndex)) {
                return r}})
            if (reportFiltered[0]) {
                setReport(reportFiltered[0])
            } else {
                const response = await reportsRoutes.getReportByIndex(otIndex)
                setReport(response.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getExecutionReport = async () => {
        console.log(recovery)
        try {
            if (recovery) {
                if (isOnline && isOperator) {
                    setOpenLinear(true)
                    const responseData = await executionReportsRoutes.getExecutionReportById(report._id, setPercentDownload)
                    setOpenLinear(false)
                    console.log(responseData.data)
                    if (responseData.data.data) {
                        const ejecucionRemotaString = JSON.stringify(responseData.data.data)
                        const ejecucionLocalString = JSON.stringify(report.tieneEjecucionEnDispositivo)
                        console.log(ejecucionRemotaString.length)
                        console.log(ejecucionLocalString.length)
                        let mensaje
                        if (ejecucionLocalString.length > ejecucionRemotaString.length) {
                            mensaje = 'La información en dispositivo es mayor a la que hay en bases de datos online. Confirme que desea ocupar esa información para continuar.'
                            /* if (confirm(mensaje)) { */
                                setExecutionReport(report.tieneEjecucionEnDispositivo)
                                setExecutionReportBackboard(report.tieneEjecucionEnDispositivo)        
                            /* } else {
                                navigate(-1)
                            } */
                        } else {
                            mensaje = 'La información en dispositivo es menor a la que hay en bases de datos online. Confirme si aún así quiere usar esta información en dispositivo.'
                            /* if (confirm(mensaje)) { */
                                setExecutionReport(report.tieneEjecucionEnDispositivo)
                                setExecutionReportBackboard(report.tieneEjecucionEnDispositivo)        
                            /* } else {
                                navigate(-1)
                            } */
                        }
                    }
                } else if (!isOnline && isOperator) {
                    /* mensaje = 'Para que la opción de recuperación sea más efectiva, se recomienda estar conectado a internet para comparar los datos en dispositivo con los descargados. Si desea usar los datos en dispositivo presione Continuar.'
                    if (confirm(mensaje)) { */
                        setExecutionReport(report.tieneEjecucionEnDispositivo)
                        setExecutionReportBackboard(report.tieneEjecucionEnDispositivo)        
                    /* } else {
                        navigate(-1)
                    } */
                }
            } else {
                /* setExecutionReport(undefined)
                setExecutionReportBackboard(undefined) */
                setMessageOnReport('Buscando Pauta')
                if (!isOperator && isOnline) {
                    setMessageOnReport('Descargando Pauta')
                    setOpenLinear(true)
                    const response = await executionReportsRoutes.getExecutionReportById(report._id, setPercentDownload)
                    setOpenLinear(false)
                    setTimeout(() => {
                        if (!response.data.data || !response.data.state) {
                            setMessage('A la espera que mantenedor inicie ejecución de pauta.')
                            setMessageOnReport('A la espera que mantenedor inicie ejecución de pauta.')
                            setReporteIniciado(false)
                        } else {
                            setExecutionReport(response.data.data)
                            setExecutionReportBackboard(response.data.data)
                        }
                        setLoading(false)
                        setTimeout(() => {
                            setMessage('')
                        }, (500));
                    }, 1500);
                } else if (isOperator && isOnline) {
                    const {database} = await executionReportsDatabase.initDb()
                    const response = await executionReportsDatabase.consultar(database)
                    const excetutionReportCache = response.filter(doc => {if(doc.reportId === report._id) return doc})
                    console.log(excetutionReportCache)
                    if (excetutionReportCache.length > 0) {
                        setOpenLinear(true)
                        const responseData = await executionReportsRoutes.getExecutionReportById(report._id, setPercentDownload)
                        setOpenLinear(false)
                        if (responseData.data.data) {
                            const ejecucionRemotaString = JSON.stringify(responseData.data.data)
                            const ejecucionLocalString = JSON.stringify(excetutionReportCache[0])
                            if (ejecucionLocalString.length > ejecucionRemotaString.length) {
                                setExecutionReport(excetutionReportCache[0])
                                setExecutionReportBackboard(excetutionReportCache[0])        
                            } else {
                                setExecutionReport(responseData.data.data)
                                setExecutionReportBackboard(responseData.data.data)        

                            }
                        } else {
                            setExecutionReport(excetutionReportCache[0])
                            setExecutionReportBackboard(excetutionReportCache[0])
                        }
                    } else {
                        setOpenLinear(true)
                        const responseData = await executionReportsRoutes.getExecutionReportById(report._id, setPercentDownload)
                        setOpenLinear(false)
                        setTimeout(async () => {
                            if (responseData && responseData.data && responseData.data.state) {
                                setExecutionReport(responseData.data.data)
                                setExecutionReportBackboard(responseData.data.data)
                                setMessageOnReport('')
                            } else {
                                if (report && report.usersAssigned[0] && (report.usersAssigned[0]._id === userData._id)) {
                                    setMessageOnReport('Guardando pauta de OT '+report.idIndex)
                                    const db = await pautasDatabase.initDbPMs()
                                    setMessage('Guardando pauta de OT '+report.idIndex)
                                    const pautas = await pautasDatabase.consultar(db.database)
                                    const pautaFiltered = pautas.filter((info) => {
                                        if((info.typepm === report.guide)&&(report.idPm===info.idpm)) {
                                                return info
                                            }})
                                    if (pautaFiltered.length > 0) {
                                        const group = await pautaFiltered[0].struct.reduce((r, a) => {
                                            r[a.strpmdesc] = [...r[a.strpmdesc] || [], a]
                                            return r
                                        }, {})
                                        const executionReportData = {
                                            reportId: report._id,
                                            createdBy: userData._id,
                                            group: group,
                                            offLineGuard: null
                                        }
                                        const responseNewExecution = await executionReportsRoutes.createExecutionReport(executionReportData)
                                        await executionReportsDatabase.actualizar(responseNewExecution.data.data, database)
                                        setExecutionReport(responseNewExecution.data.data)
                                        setExecutionReportBackboard(responseNewExecution.data.data)
                                        if (!report.dateInit) {
                                            report.dateInit = new Date()
                                            await reportsRoutes.editReportById(report)
                                            setMessageOnReport('')
                                        }
                                    } else {
                                        if (isOnline) {
                                            const pautas = await patternsRoutes.getPatternsDetailByIdpm(report.idPm)
                                            const pautaFiltered = pautas.data.data.filter((info) => {
                                                if((info.typepm === report.guide)&&(report.idPm===info.idpm)) {
                                                        return info
                                                    }})
                                            if (pautaFiltered.length > 0) {
                                                const group = await pautaFiltered[0].struct.reduce((r, a) => {
                                                    r[a.strpmdesc] = [...r[a.strpmdesc] || [], a]
                                                    return r
                                                }, {})
                                                const executionReportData = {
                                                    reportId: report._id,
                                                    createdBy: userData._id,
                                                    group: group,
                                                    offLineGuard: null
                                                }
                                                const responseNewExecution = await executionReportsRoutes.createExecutionReport(executionReportData)
                                                await executionReportsDatabase.actualizar(responseNewExecution.data.data, database)
                                                setExecutionReport(responseNewExecution.data.data)
                                                setExecutionReportBackboard(responseNewExecution.data.data)
                                                if (!report.dateInit) {
                                                    report.dateInit = new Date()
                                                    await reportsRoutes.editReportById(report)
                                                    setMessageOnReport('')
                                                }
                                            }
                                        } else {
                                            setMessageOnReport(report.idPm+' para ' + report.guide + ' no encontrado en bases de datos. Cierre sesión, luego inicie y vuelva a intentar. Si el problema persiste contacte al administrador.')
                                        }                                
                                    } 
                                } else {
                                    setMessageOnReport(report.idPm+' para ' + report.guide + ' no encontrado en bases de datos locales y no se pudo obtener desde internet. Conectese a una red, reinicie sesión y vuelva a intentar. Si el problema persiste contacte al administrador.')
                                }
                                setMessage('No se logra descargar ejecución de datos.')
                                setTimeout(() => {
                                    setMessage('')
                                }, 1000);
                            }
                        }, 500);
                        setTimeout(() => {
                            setMessage('')
                        }, 1000);
                    }
                } else if (isOperator && !isOnline) {
                    const {database} = await executionReportsDatabase.initDb()
                    const response = await executionReportsDatabase.consultar(database)
                    const executionReportCache = response.filter(doc => {if(doc.reportId === report._id) return doc})
                    if (executionReportCache.length > 0) {
                        setExecutionReport(executionReportCache[0])
                        setExecutionReportBackboard(executionReportCache[0])
                    }
                    setTimeout(() => {
                        setMessage('')
                    }, 1000);
                }
                setMessage('')
            }
        } catch (error) {
            await errorDatabase.agregar({id: Date.now(), data: error})
        }
    }

    const selectionItem = (value = new String()) => {
        const state = detectIf3DModelExist(value, machineData.model)
        let system = {
            name: translateSubSystem(value),
            brand: machineData.brand,
            model: machineData.model,
            nameModel: `${translateSubSystem(value)}_${machineData.model}`
        }
        setSubSistem(JSON.stringify(system))
        setHabilite3D(state)
    }

    const endReport = async (text) => {
        setMessageType((text==='aprobado') ? 'sendReport' : 'rejectReport')
        setIsTermJornada(false)
        setOpenReportCommitModal(true)
        setOpenMenu(false)
    }

    const mensajeTerminoJornada = () => {
        setIsTermJornada(true)
        setOpenReportCommitModal(true)
        setOpenMenu(false)
    }

    const forwardReport = () => {
        if (isOnline) {
            setToForward(true)
            endReport('aprobado')
        } else {
            alert('Dispositivo debe estar conectado a internet.')
        }
        setOpenMenu(false)
    }

    const rejectReport = () => {
        if (isOnline) {
            setToForward(false)
            endReport('rechazado')
        } else {
            alert('Dispositivo debe estar conectado a internet.')
        }
        setOpenMenu(false)
    }

    const vistaPrevia = async () => {
        setOpenMenu(false)
        if (report.level && report.level > 0) {
            const listaMateriales = []
            const db = await executionReportsDatabase.initDb()
            const data = await executionReportsDatabase.consultar(db.database)
            const executionReportFiltered = data.filter((execution) => {
                                                if(execution.reportId === report._id) {
                                                    return execution
                                                }
                                            })
            Object.keys(executionReportFiltered[0].group).forEach((element, index) => {
                const hoja = {
                    nombre: element,
                    data: []
                }
                executionReportFiltered[0].group[element].forEach((item, i) => {
                    if (item.unidad === '*') {

                    } else {
                        const materialData = {
                            task: item.task,
                            taskdesc: item.taskdesc,
                            obs01: item.obs01,
                            partnumberUtl: item.partnumberUtl,
                            cantidad: item.cantidad,
                            unidad: item.unidad,
                            unidadData: item.unidadData ? Number(item.unidadData) : 0,
                            diferencia: parseFloat(item.cantidad.toString()) - parseFloat(item.unidadData ? item.unidadData.toString() : '0')
                        }
                        hoja.data.push(materialData)
                    }
                    if (i === (executionReportFiltered[0].group[element].length - 1)) {
                        if (hoja.data.length > 0) {
                            listaMateriales.push(hoja)
                        }
                    }
                })
                if (index === (Object.keys(executionReportFiltered[0].group).length - 1)) {
                    setMaterialesPreview(listaMateriales)
                }
            })
            setOpenPreviewModal(true)
        } else {
            alert('Orden aún no está listo para revisar el listado de materiales o insumos.')
        }
    }

    const openMessages = () => {
        setOpenMessagesModal(true)
        setOpenMenu(false)
    }

    const closeMessages = () => {
        setOpenMessagesModal(false)
    }

    const continuarTerminarJornada = async () => {
        if(isOnline) {
            if (window.confirm('Confirme que terminará su jornada')) {
                try {
                    setIsTermJornada(true)
                    setLoadingLogo(true)
                    const reportCache = report
                    const emailing = "termino-jornada"
                    const fullNameWorker = `${userData.name} ${userData.lastName}`
                    /* const emails = await getExecutivesSapEmail(reportCache.level ? reportCache.level : 0)
                    console.log(emails) */
                    const emailsToSend = []/* emails */
                    reportCache.progress = itemProgress
                    reportCache.state = 'Asignar'
                    reportCache.emailing = emailing
                    reportCache.fullNameWorker = fullNameWorker
                    reportCache.emailsToSend = emailsToSend
                    setMessage('Guardando OT en base de datos.')
                    console.log('Guardando OT en base de datos.')

                    const executionReportCache = executionReport
                    await executionReportsRoutes.saveExecutionReport(executionReportCache)
                    const actualiza = await reportsRoutes.editReport(reportCache)
                    if(actualiza) {
                        const {database} = await reportsDatabase.initDbReports()
                        const stateData = await reportsDatabase.actualizar(reportCache, database)
                        setTimeout(() => {
                            SocketConnection.sendnotificationToManyUsers(
                                'termino-jornada',
                                `${userData._id}`,
                                'Aviso general',
                                'Término de jornada',
                                `${userData.name} ${userData.lastName} ha terminado su jornada. Recuerde reasignar OT`,
                                '/reports',
                                null,
                                site._id
                            )
                            alert('Se ha actualizado su reporte. La orden desaparecerá de su listado.')
                            navigate(-1)
                            setLoadingLogo(false)
                            setMessage('')
                            getReports()
                            /* getReportsOffline() */
                        }, 500)
                    }
                } catch (error) {
                    await errorDatabase.agregar({id: Date.now(), data: error})
                }
            }
        }else{
            setLoadingLogo(false)
            alert('Dispositivo no está conectado a internet. Conecte a una red e intente nuevamente')
        }
    }

    const terminarjornada = async () => {
        continuarTerminarJornada()
        /* setOpenReportCommitModal(false)
        activarConfirmacion('Terminará jornada. Si está de acuerdo presione continuar.', continuarTerminarJornada()) */
    }

    const openBacklogsResume = () => {
        setOpenResumeBacklogsDialog(true)
        const group = executionReport.group
        const newGroup = {}
        Object.keys(group).forEach(key => {
            group[key].forEach((item) => {
                if (!newGroup[key]) {
                    newGroup[key] = []
                }
                if (item.backlogList && item.backlogList.length > 0) {
                    newGroup[key].push(item)
                }
            })
        })
        setContenidoResumenBacklog(newGroup)
    }

    

    const closeCommitModalToBack = async (state) => {
        setOpenReportCommitModal(false)
        if (state) {
            await guardarContador(false)
            setLoadingLogo(true)
            const reportCache = report
            reportCache.progress = itemProgress
            if (reportCache.testMode) {
                let level
                if (report.reportType === 'Mantención') {
                    level = report.level - 1
                } else {
                    if (report.level === 2) {
                        level = report.level - 2
                    } else {
                        level = report.level - 1
                    }
                }
                reportCache.level = level
                reportCache.emailing = `rechazo-orden-${level}`
                if (level === 2) {
                } else if (level === 3) {
                    reportCache.state = 'En proceso'
                }
                /* sendnotificationToManyUsers(reportCache.emailing, reportCache.idIndex, reportCache.history[reportCache.history.length - 1].userSendingData, userData._id, site._id) */
                saveReportToDatabases(reportCache)
            } else {
                if (itemProgress === 100) {
                    let level
                    if (report.reportType === 'Mantención') {
                        level = report.level - 1
                    } else {
                        if (report.level === 2) {
                            level = report.level - 2
                        } else {
                            level = report.level - 1
                        }
                    }
                    reportCache.level = level
                    reportCache.emailing = `rechazo-orden-${level}`
                    if (level === 2) {
                    } else if (level === 3) {
                        reportCache.state = 'En proceso'
                    }
                    /* sendnotificationToManyUsers(reportCache.emailing, reportCache.idIndex, reportCache.history[reportCache.history.length - 1].userSendingData, userData._id, site._id) */
                    saveReportToDatabases(reportCache)
                } else {
                    setLoadingLogo(false)
                    alert('Reporte está incompleto.')
                }
            }
        }
    }

    const guardarContador = (nav) => {
        return new Promise(async resolve => {
            const ejecucionCache = ejecucion
            if (ejecucionCache.inicio) {
                ejecucionCache.termino = new Date()
                const reportCache = report
                if (!reportCache.ejecuciones) {
                    reportCache.ejecuciones = []
                } else {
                    const res = reportCache.ejecuciones.findIndex(item => item.inicio === ejecucionCache.inicio)
                    if (res === -1) {
                        reportCache.ejecuciones.push(ejecucionCache)
                    } else {
                        reportCache.ejecuciones[res] = ejecucionCache
                    }
                }
                setReport(reportCache)
                if (nav) {
                    if (isOnline) {
                        if (report)
                        guardarContadorEnReporte(reportCache)
                    }
                    navigate(-1)
                    setEjecucion({
                        inicio: null,
                        termino: null,
                        userId: null
                    })
                }
            }
            resolve(true)
        })
    }


    const saveReportToDatabases = async (report) => {
        try {
            await guardarContador(false)
            setLoadingMessage('Guardando en bases de datos')
            const responseDatabase = await executionReportsDatabase.initDb()
            const executionReportCache = executionReport
            executionReportCache.offLineGuard = Date.now()
            const group = executionReportCache.group
            let fileList
            let totalBacklogsCache = 0
            Object.keys(group).forEach(key => {
                group[key].forEach(item => {
                    if (item.backlogList && (item.backlogList.length > 0)) {
                        totalBacklogsCache = totalBacklogsCache + item.backlogList.length
                        item.backlogList.forEach(backlog => {
                            if (backlog.images && backlog.images.length > 0) {
                                backlog.images.forEach(async imagen => {
                                    fileList = await Promise.all(files.map(async file => {
                                        const fileUploaded  = await azureStorageRoutes.uploadImage(
                                            file,
                                            `${new Date().getFullYear()}/${report.idIndex}/${item.strpmdesc}/backlog/${Date.now()}`,
                                            'reports-test'
                                            );
                                        const url = fileUploaded.data.data.url
                                        return url
                                    }))
                                })
                                backlog.fileList = fileList
                                backlog.images = null
                            }
                        })
                        
                    }
                })
            })
            setTotalBacklogs(totalBacklogsCache)
            await executionReportsDatabase.actualizar(executionReportCache, responseDatabase.database)
            if (isOnline) {
                await executionReportsRoutes.saveExecutionReport(executionReportCache)
            }
            saveReportToData(report)
            alert(report.level === 4 ? 'Orden de trabajo cerrada correctamente' : 'Orden de trabajo enviado a revisión')
            navigate(-1)
            setLoadingLogo(false)
        } catch (error) {
            alert('Un error ha ocurrido')
            await errorDatabase.agregar(error)
            console.log(error)
        }
    }

    const closeCommitModalToForward = async (state) => {
        setOpenReportCommitModal(false)
        if (state) {
            await guardarContador(false)
            setLoadingLogo(true)
            setLoadingMessage('Guardando información')
            const reportCache = report
            reportCache.progress = itemProgress
            if (reportCache.testMode) {
                let level = 0
                if (!reportCache.level||reportCache.level === 0) {
                    if (report.reportType === 'Mantención') {
                        level = 1
                    } else {
                        level = 2
                    }
                    reportCache.endReport = new Date()
                } else {
                    level = reportCache.level + 1
                }
                reportCache.emailing = `termino-orden-${level}`
                if (level === 2) {
                    reportCache.shiftManagerApprovedBy = userData._id
                    reportCache.shiftManagerApprovedDate = new Date()
                } else if (level === 3) {
                    reportCache.chiefMachineryApprovedBy = userData._id
                    reportCache.chiefMachineryApprovedDate = new Date()
                    reportCache.state = 'Por cerrar'
                } else if (level === 4) {
                    reportCache.sapExecutiveApprovedBy = userData._id
                    reportCache.dateClose = new Date()
                    reportCache.state = 'Completadas'
                    reportCache.level = level
                    await pdfMakeRoutes.createPdfDoc(reportCache)
                }
                reportCache.level = level
                /* sendnotificationToManyUsers(reportCache.emailing, reportCache.idIndex, reportCache.history[reportCache.history.length - 1].userSendingData, userData, site._id) */
                saveReportToDatabases(reportCache)
            } else {
                if (itemProgress === 100) {
                    let level = 0
                    if (!reportCache.level||reportCache.level === 0) {
                        if (report.reportType === 'Mantención') {
                            level = 1
                        } else {
                            level = 2
                        }
                        reportCache.endReport = new Date()
                    } else {
                        level = reportCache.level + 1
                    }
                    reportCache.emailing = `termino-orden-${level}`
                    if (level === 2) {
                        reportCache.shiftManagerApprovedBy = userData._id
                        reportCache.shiftManagerApprovedDate = new Date()
                    } else if (level === 3) {
                        reportCache.chiefMachineryApprovedBy = userData._id
                        reportCache.chiefMachineryApprovedDate = new Date()
                        reportCache.state = 'Por cerrar'
                    } else if (level === 4) {
                        reportCache.sapExecutiveApprovedBy = userData._id
                        reportCache.dateClose = new Date()
                        reportCache.state = 'Completadas' 
                        await pdfMakeRoutes.createPdfDoc(reportCache)    
                    }
                    reportCache.level = level
                    /* sendnotificationToManyUsers(reportCache.emailing, reportCache.idIndex, reportCache.history[reportCache.history.length - 1].userSendingData, userData, site._id) */
                    saveReportToDatabases(reportCache)
                } else {
                    setLoadingLogo(false)
                    alert('Reporte está incompleto.')
                }
            }
        }
    }


    const provider = {
        report,
        setReport,
        executionReport,
        executionReportBackboard,
        setExecutionReport,
        setExecutionReportBackboard,
        reportId,
        sapId,
        serieEquipo,
        modoTest,
        avanceHoja,
        avanceTotal,
        otIndex,
        setAvanceHoja,
        setAvanceTotal,
        loading,
        setLoading,
        setOtIndex,
        setLoadingMessage,
        reporteIniciado,
        messageOnReport,
        actualizacionEjecucion,
        ejecucionActualizada,
        setOpenLinear,
        setMessageBacklog,
        messageBacklog,
        selectionItem,
        habilite3D,
        puedeEnviar,
        puedeCerrar,
        puedeRechazar,
        puedeTerminarJornada,
        materialesPreview,
        totalBacklogs,
        progress, 
        resutProgress,
        itemProgress,
        resultThisItemProgress,
        mensajeTerminoJornada,
        forwardReport,
        rejectReport,
        vistaPrevia,
        openMessages,
        closeMessages,
        openMessagesModal,
        openBacklogsResume,
        contenidoResumenBacklog,
        openMenu,
        setOpenMenu,
        setIsTermJornada,
        setOpenReportCommitModal,
        toForward,
        ejecucion,
        setEjecucion,
        saveExecutionReportToDb,
        setRecovery
    }

    return (
        <>
        <LianearProgresDialog open={openLinear} progress={percentDownload} />
        <ExecutionReportContext.Provider value={provider} {...props} />
        {
            openReportCommitModal && <ReportCommitDialog 
                isTermJornada={isTermJornada}
                terminarjornada={terminarjornada}
                puedeEnviar={puedeEnviar}
                open={openReportCommitModal} 
                closeModal={(toForward) ? closeCommitModalToForward : closeCommitModalToBack}
                report={report} 
                messageType={messageType}/>
        }
        {
            openMessagesModal && <ReportMessagesDialog 
                open={openMessagesModal} 
                close={closeMessages} 
                report={report} />
        }
        {
            openResumeBacklogsDialog && <BacklogsResume
                setOpenResumeBacklogsDialog={setOpenResumeBacklogsDialog}
                openResumeBacklogsDialog={openResumeBacklogsDialog}
                contenidoResumenBacklog={contenidoResumenBacklog}
            />
        }
        </>
    )
}

export const useExecutionReportContext = () => useContext(ExecutionReportContext)