import axios from 'axios'

export const crearDatosDispositivo = async (data) => {
    const response = await axios.post('/device/crearDatosDispositivo', data)
    return response.data
}

export const guardarDispositivo = async (datos) => {
    const response = await axios.post('/guardarDispositivo', datos)
    return response.data
}