import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Grid } from '@mui/material'
import { patternsRoutes } from '../../routes'
import { useState } from 'react'
import { CircularProgressPercent, PDFFileDialog } from '../../dialogs'
import { useConnectionContext, useReportsContext } from '../../context'

const PatternList = ({patterns}) => {
    const {isOnline} = useConnectionContext()
    const {downloadPautas} = useReportsContext()
    const [percent, setPercent] = useState(0)
    const [openLoading, setOpenLoading] = useState(false)
    const [openPDF, setOpenPdf] = useState(false)
    const [pdfFile, setPdfFile] = useState()

    /* const openPage = (url) => {
        window.open(`${process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : 'https://mantencion.icv.cl'}${url}`, '_blank')
    } */

    const downloadFile = (url) => {
        if (!isOnline) {
            alert('Debe estar conectado a internet')
        } else {
            const urlPDF = `${process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : 'https://mantencion.icv.cl'}${url}`
            setPdfFile(urlPDF)
            setOpenPdf(true)
        }
    }

    const openUploadDocuments = (pattern) => {
        const input = document.getElementById('openDocuments')
        if (input) {
            input.click()

            input.onchange = (e) => {
                const fileList = e.target.files
                console.log(fileList)
                console.log(pattern)
                setOpenLoading(true)
                setPercent(0)
                sendFileOneByOne(fileList, 0, pattern)
            }
        }
    }

    const sendFileOneByOne = async (fileList, index, pattern) => {
        if (!fileList[index]) {
            setPercent(100)
            setTimeout(() => {
                alert('Archivos guardados')
                setOpenLoading(false)
            }, 500)
            downloadPautas()
        } else {
            const currentPercent = (100 / fileList.length) * index
            setPercent(currentPercent)
            const file = fileList[index]
            const response = await patternsRoutes.saveFilesToPattern(file, pattern._id)
            console.log(response)
            sendFileOneByOne(fileList, index + 1, pattern)
        }
    }

    return (
        <div style={{ padding: 10, width: '100%', height: 'calc(100vh - 300px)', textAlign: 'left', color: '#333', backgroundColor: '#F9F9F9', borderRadius: 20 }}>
            <CircularProgressPercent
                open={openLoading}
                number={percent}
            />
            {(openPDF && pdfFile && isOnline) && 
                <PDFFileDialog
                    open={openPDF}
                    file={pdfFile}
                    handleClose={() => {setOpenPdf(false)}}
                />
            }
            <Grid container>
                <Grid item xs={1} style={{padding: 5}}>
                    <strong>ID</strong>
                </Grid>
                <Grid item xs={2} style={{padding: 5}}>
                    <strong>Tipo</strong>
                </Grid>
                <Grid item xs={1} style={{padding: 5}}>
                    <strong>PIDPM</strong>
                </Grid>
                <Grid item xs={1} style={{padding: 5}}>
                    <strong>Zona</strong>
                </Grid>
                <Grid item xs={3} style={{padding: 5}}>
                    <strong>Documentos</strong>
                </Grid>
                <Grid item xs={1} style={{padding: 5}}>
                    <strong>Acciones</strong>
                </Grid>
            </Grid>
            {
                (patterns.length === 0)
                ?
                <div style={{ width: '100%', textAlign: 'left', paddingTop: 50 }}>
                    No hay pautas creadas
                </div>
                :
                <div style={{ width: '100%', paddingTop: 50, height: 'calc(100vh - 350px)', overflowY: 'auto', fontSize: 12 }}>
                    {
                        patterns.map((pattern, i) => {
                            return (
                                <Grid container key={i} style={{ paddingTop: (i === 0) ? 0 : 10, paddingBottom: 10 }}>
                                    <Grid item xs={1} style={{padding: 5}}>
                                        {pattern.id}
                                    </Grid>
                                    <Grid item xs={2} style={{padding: 5}}>
                                        {pattern.typepm}
                                    </Grid>
                                    <Grid item xs={1} style={{padding: 5}}>
                                        {pattern.idpm}
                                    </Grid>
                                    <Grid item xs={1} style={{padding: 5}}>
                                        {pattern.zone}
                                    </Grid>
                                    <Grid item xs={3} style={{padding: 5}}>
                                        {
                                            (pattern.docs && pattern.docs.length > 0) ? 
                                            pattern.docs.map((doc, index) => {
                                                return (
                                                    <p className='files-link' id={`doc_${pattern.typepm}_${pattern.idpm}`} onClick={() => {downloadFile(doc.url)}} key={index}>
                                                        - {doc.name}
                                                    </p>
                                                )
                                            })
                                            :
                                            'No hay documentos.'
                                        }
                                    </Grid>
                                    <Grid item xs={1} style={{padding: 5}}>
                                        <Button name={'Subir documentos'} onClick={() => {openUploadDocuments(pattern); setPdfFile(undefined)}}>
                                            <FontAwesomeIcon icon={faUpload} />
                                        </Button>
                                        <input multiple id={'openDocuments'} style={{display: 'none'}} type="file" accept="application/pdf" />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default PatternList
